import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// eslint-disable-next-line @nx/enforce-module-boundaries
import {
    ShipmentAttachment,
    ShipmentAttachmentsFacade,
} from '@qwyk/shared-stores/shipment-attachments';
import {
    ShipmentAttachmentsListItemComponent
} from '../shipment-attachments-list-item/shipment-attachments-list-item.component';
import { AttachmentsCarouselComponent } from '../attachments-carousel/attachments-carousel.component';

@Component({
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        NgClass,
        AsyncPipe,
        TranslateModule,
        FontAwesomeModule,
        AttachmentsCarouselComponent,
        ShipmentAttachmentsListItemComponent,
    ],
    selector: 'qwyk-shipment-attachments-list',
    templateUrl: './shipment-attachments-list.component.html',
    styleUrls: ['./shipment-attachments-list.component.scss'],
})
export class ShipmentAttachmentsListComponent {
    @Input() showHeader = true;
    @Input() cardClass: string;
    @Input() headerClass: string;
    @Input() headingClass: string;
    @Input() loading = false;
    @Input() disabled = false;
    @Input() shipmentAttachments: ShipmentAttachment[];
    @Input() canUpload = true;
    @Input() canDelete = false;

    @Output() uploadAttachment: EventEmitter<void> = new EventEmitter();
    @Output()
    downloadAttachment: EventEmitter<ShipmentAttachment> =
        new EventEmitter<ShipmentAttachment>();
    @Output()
    deleteAttachment: EventEmitter<ShipmentAttachment> =
        new EventEmitter<ShipmentAttachment>();

    public showCarousel: boolean;
    public activeIndex: number;
    public preservePagination = false;
    public lastVisitedPage: number;

    public carouselData$ = combineLatest([
        this.attachments.attachmentLinks$,
        this.attachments.loading$,
    ]).pipe(
        map(([attachmentLinks, loading]) => ({
            attachmentLinks,
            loading,
        }))
    );

    constructor(private readonly attachments: ShipmentAttachmentsFacade) {}

    public onUploadAttachment(): void {
        this.uploadAttachment.emit();
    }

    public onDownloadAttachment(attachment: ShipmentAttachment): void {
        this.downloadAttachment.emit(attachment);
    }

    public onDeleteAttachment(attachment: ShipmentAttachment): void {
        this.deleteAttachment.emit(attachment);
    }

    public onOpenCarousel(id: string, index: number): void {
        this.showCarousel = true;
        this.activeIndex = index;
    }

    public onHideCarousel($event: boolean): void {
        this.showCarousel = !$event;
    }

    public onSaveData(page: number): void {
        this.preservePagination = true;
        this.lastVisitedPage = page;
    }
}
