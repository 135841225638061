import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { EnvironmentConfig } from '../environmentConfig';
import algoliasearch, { SearchClient, SearchIndex } from 'algoliasearch/lite';
import * as Sentry from '@sentry/angular';

interface additionalQuery {
    optionalFilters: any[];
    aroundLatLng?: string;
    aroundRadius?: number;
}

@Injectable({
    providedIn: 'root',
})
export class AlgoliaLocationsService {
    private index: SearchIndex;
    private client: SearchClient;

    constructor(environment: EnvironmentConfig) {
        this.client = algoliasearch(
            environment.utils.Algolia.applicationId,
            environment.utils.Algolia.adminApiKey
        );

        this.index = this.client.initIndex('locations');
    }

    getLocationSuggestions(
        query: string,
        prefer?: string[],
        aroundLatLng?: string,
        aroundRadius?: number
    ): Observable<any[]> {
        const additionalQuery: additionalQuery = {
            optionalFilters: prefer || [],
        };
        if (aroundLatLng) {
            additionalQuery.aroundLatLng = aroundLatLng;
        }
        additionalQuery.aroundRadius = aroundRadius ?? 100000;

        const observable = from(this.index.search(query, additionalQuery));

        return observable.pipe(
            map(response => {
                return response.hits.map((e: any) => {
                    e.place_name = e.display_name;
                    e.highlight = `${e._highlightResult.display_name.value}, ${e._highlightResult.country_name.value}`;
                    return e;
                });
            })
        );
    }

    getNearbyCity(
        lat: number,
        lng: number,
        cityName: string,
        prefer: string[] = []
    ): Observable<any> {
        const observable = from(
            this.index.search(cityName ?? '', {
                aroundLatLng: `${lat},${lng}`,
                aroundRadius: 100000,
                hitsPerPage: 1,
                optionalWords: cityName ?? '',
                optionalFilters: prefer,
            })
        );

        return observable.pipe(
            take(1),
            switchMap(response => {
                if (response.hits && response.hits.length > 0) {
                    return of(response.hits[0]);
                } else {
                    Sentry.captureMessage(
                        `[CityLocodeResolution] Unable to find nearby city for '${cityName}' with pos: ${lat}, ${lng}`,
                        {
                            level: 'warning',
                            fingerprint: [
                                'CityLocodeResolution',
                                cityName,
                                (lat ?? '').toString(),
                                (lng ?? '').toString(),
                            ],
                            extra: {
                                name: cityName,
                                lat: (lat ?? '').toString(),
                                lng: (lng ?? '').toString(),
                            },
                            tags: {
                                name: cityName,
                            },
                        }
                    );
                    return from(
                        this.index.search('', {
                            aroundLatLng: `${lat},${lng}`,
                            hitsPerPage: 1,
                            optionalFilters: prefer ?? [],
                            aroundRadius: 150000,
                        })
                    ).pipe(
                        take(1),
                        map(respo =>
                            respo.hits && respo.hits.length > 0
                                ? respo.hits[0]
                                : null
                        )
                    );
                }
            })
        );
    }
}
