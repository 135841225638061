import {Component, Input} from '@angular/core';
import {ShipmentActivity} from '@qwyk/shared-stores/shipment-activities';

@Component({
    standalone: true,
    template: '',
})
export abstract class ShipmentActivitiesListItemComponent {
    @Input() public shipmentActivity: ShipmentActivity;
}
