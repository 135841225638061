<p-galleria
    [value]="attachmentLinksResolved"
    [visible]="showCarousel"
    (visibleChange)="onVisibleChange($event)"
    [numVisible]="1"
    [containerStyle]="{'max-width': '50%'}"
    (activeIndexChange)="onActiveIndexChange($event)"
    [activeIndex]="currentIndex"
    [fullScreen]="true"
    [showItemNavigators]="true"
    [showThumbnails]="false"
    >
    <ng-template pTemplate="item" let-item>
        <ng-container *ngIf="item?.attachment_link?.data?.url && !item.isImage">
            <fa-icon
                class="text-primary"
                size="10x"
                [icon]="['fas', 'file']">
            </fa-icon>
        </ng-container>

        <ng-container *ngIf="item?.attachment_link?.data?.url && item.isImage">
            <img [src]="item?.attachment_link?.data?.url" style="width: 100%; display: block;" alt="#" />
        </ng-container>

        <span>{{ item.filename}}</span>
        <span class="small">{{ item.description }}</span>
    </ng-template>
</p-galleria>
