import { Component, Input } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { Portals } from '@qwyk/models';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgClass } from '@angular/common';

export enum MappedCargoItemStatus {
    ON_HAND = 0,
    IN_PROGRESS = 1,
    LOADED = 2,
    IN_TRANSIT = 3,
    AT_DESTINATION = 4,
    DELIVERED = 5,
    ARRIVING = 6,
    IN_QUOTE = 7,
    AVAILABLE = 8,
    BOOKED = 9,
    PROCESSED = 10,
    PENDING = 11,
    ORDERED = 12,
    BACK_ORDERED = 13,
    CANCELED = 14,
}

@Component({
    standalone: true,
    imports: [NgClass, FontAwesomeModule],
    selector: 'qwyk-cargo-item-status-badge',
    templateUrl: './cargo-items-status-badge.component.html',
    styleUrls: ['./cargo-items-status-badge.component.scss'],
})
export class CargoItemsStatusBadgeComponent {
    @Input() public cargoItem: Portals.CargoItem;

    constructor(private _translate: TranslateService) {}

    private icons = {
        [MappedCargoItemStatus.ON_HAND]: 'warehouse',
        [MappedCargoItemStatus.IN_PROGRESS]: 'sync',
        [MappedCargoItemStatus.LOADED]: 'truck-loading',
        [MappedCargoItemStatus.IN_TRANSIT]: 'ship',
        [MappedCargoItemStatus.AT_DESTINATION]: 'boxes',
        [MappedCargoItemStatus.DELIVERED]: 'hand-holding-box',
        [MappedCargoItemStatus.ARRIVING]: 'truck-loading',
        [MappedCargoItemStatus.PENDING]: 'clock',
        [MappedCargoItemStatus.IN_QUOTE]: 'hand-holding-usd',
        [MappedCargoItemStatus.AVAILABLE]: 'check',
        [MappedCargoItemStatus.BOOKED]: 'file-signature',
        [MappedCargoItemStatus.PROCESSED]: 'check-square',
        [MappedCargoItemStatus.ORDERED]: 'shopping-cart',
        [MappedCargoItemStatus.BACK_ORDERED]: 'arrow-to-left',
        [MappedCargoItemStatus.CANCELED]: 'times-circle',
    };

    private labels = {
        [MappedCargoItemStatus.ON_HAND]: 'portal.cargo-items.states.on-hand',
        [MappedCargoItemStatus.IN_PROGRESS]:
            'portal.cargo-items.states.in-process',
        [MappedCargoItemStatus.LOADED]: 'portal.cargo-items.states.loaded',
        [MappedCargoItemStatus.IN_TRANSIT]:
            'portal.cargo-items.states.in-transit',
        [MappedCargoItemStatus.AT_DESTINATION]:
            'portal.cargo-items.states.at-destination',
        [MappedCargoItemStatus.DELIVERED]:
            'portal.cargo-items.states.delivered',
        [MappedCargoItemStatus.ARRIVING]: 'portal.cargo-items.states.arriving',
        [MappedCargoItemStatus.PENDING]: 'portal.cargo-items.states.pending',
        [MappedCargoItemStatus.IN_QUOTE]: 'portal.cargo-items.states.in-quote',
        [MappedCargoItemStatus.AVAILABLE]:
            'portal.cargo-items.states.available',
        [MappedCargoItemStatus.BOOKED]: 'portal.cargo-items.states.booked',
        [MappedCargoItemStatus.PROCESSED]:
            'portal.cargo-items.states.processed',
        [MappedCargoItemStatus.ORDERED]: 'portal.cargo-items.states.ordered',
        [MappedCargoItemStatus.BACK_ORDERED]:
            'portal.cargo-items.states.back-ordered',
        [MappedCargoItemStatus.CANCELED]: 'portal.cargo-items.states.cancelled',
    };

    private colors = {
        [MappedCargoItemStatus.ON_HAND]: 'success',
        [MappedCargoItemStatus.IN_PROGRESS]: 'primary',
        [MappedCargoItemStatus.LOADED]: 'success',
        [MappedCargoItemStatus.IN_TRANSIT]: 'primary',
        [MappedCargoItemStatus.AT_DESTINATION]: 'primary',
        [MappedCargoItemStatus.DELIVERED]: 'success',
        [MappedCargoItemStatus.ARRIVING]: 'primary',
        [MappedCargoItemStatus.PENDING]: 'dark',
        [MappedCargoItemStatus.IN_QUOTE]: 'primary',
        [MappedCargoItemStatus.AVAILABLE]: 'success',
        [MappedCargoItemStatus.BOOKED]: 'success',
        [MappedCargoItemStatus.PROCESSED]: 'success',
        [MappedCargoItemStatus.ORDERED]: 'success',
        [MappedCargoItemStatus.BACK_ORDERED]: 'primary',
        [MappedCargoItemStatus.CANCELED]: 'danger',
    };

    public get icon(): string {
        return this.icons[this.cargoItem.status];
    }

    public get label(): string {
        return this._translate.instant(this.labels[this.cargoItem.status]);
    }

    public get color(): string {
        return this.colors[this.cargoItem.status];
    }
}
