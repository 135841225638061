import { Component } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
    standalone: true,
    imports: [FontAwesomeModule],
    selector: 'qwyk-on-hold-indicator',
    templateUrl: './on-hold-indicator.component.html',
    styleUrls: ['./on-hold-indicator.component.scss'],
})
export class OnHoldIndicatorComponent {
}
