import { Component } from '@angular/core';

import { MomentModule } from 'ngx-moment';
import { TranslateModule } from '@ngx-translate/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { LineBreaksPipe } from '../../pipes';
import { ShipmentActivitiesListItemComponent } from '../shipment-activities-list-item/shipment-activities-list-item.component';

@Component({
    standalone: true,
    imports: [LineBreaksPipe, MomentModule, NgbTooltipModule, FontAwesomeModule, TranslateModule],
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'li[qwyk-shipment-activities-comment]',
    templateUrl: './shipment-activities-comment.component.html',
    styleUrls: ['./shipment-activities-comment.component.scss'],
})
export class ShipmentActivitiesCommentComponent extends ShipmentActivitiesListItemComponent {}
