import { IconName } from '@fortawesome/pro-duotone-svg-icons';

export const PRODUCT_TITLES = {
    fcl: 'FCL',
    lcl: 'LCL',
    csl: 'Consol',
    air: 'Air',
};

export const PRODUCT_ICONS: { [index: string]: IconName } = {
    fcl: 'ship',
    lcl: 'pallet-alt',
    csl: 'layer-group',
    air: 'plane-alt',
};

export const PRODUCT_COLORS = {
    fcl: 'primary',
    lcl: 'warning',
    csl: 'success',
    air: 'danger',
};

export interface TransportMode {
    [index: string]: IconName | { [index: string]: IconName };
}

export const TRANSPORT_MODE_ICONS: TransportMode = {
    'OCEAN': 'ship',
    'AIR': 'plane',
    'TRUCK': 'truck',
    'RAIL': 'train',
    'UNDETER': 'question',
    'pol': {
        'OCEAN': 'anchor',
        'AIR': 'plane-departure',
        'TRUCK': 'truck-loading',
        'RAIL': 'truck-ramp',
        'UNDETER': 'truck-loading',
    },
    'pod': {
        'OCEAN': 'anchor',
        'AIR': 'plane-arrival',
        'TRUCK': 'forklift',
        'RAIL': 'truck-ramp',
        'UNDETER': 'forklift',
    },
};

export const LOAD_TYPE_ICONS = {
    fcl: 'container-storage',
    lcl: 'pallet-alt',
};
