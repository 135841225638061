import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@qwyk/portals/environment';
import { map, take } from 'rxjs/operators';
import { ShipmentSubscriptionPayload, ShipmentSubscriptionResponse } from './ShipmentSubscription';

@Injectable({
    providedIn: 'root',
})
export class ShipmentSubscriptionService {
    private readonly http = inject(HttpClient);

    public upsertShipmentSubscription(shipmentId: string, payload: ShipmentSubscriptionPayload) {
        payload.shipment_status_notification = payload.shipment_status_notification ?? false;
        payload.shipment_activity_notification = payload.shipment_activity_notification ?? false;
        return this.http
            .patch<{ data: ShipmentSubscriptionResponse }>(
                `${environment.backendServer}/api/portals/shipments/v2/${shipmentId}/subscription`,
                payload
            )
            .pipe(
                take(1),
                map(d => d.data)
            );
    }
}
