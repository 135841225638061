import { Pipe, PipeTransform } from '@angular/core';
import * as prettyBytes from 'pretty-bytes';

@Pipe({
    standalone: true,
    name: 'prettyBytes',
})
export class PrettyBytesPipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    transform(value: number, ...args: unknown[]): unknown {
        return value && !isNaN(value) ? prettyBytes(value) : null;
    }
}
