import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    standalone: true,
    name: 'urlEncode',
})
export class UrlEncodePipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    transform(value: string, ...args: unknown[]): unknown {
        return encodeURIComponent(value).replace('%20', '%2B');
    }
}
