import { Component, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { BookingWizardOffersFacade } from '../../state/booking-wizard-offers.facade';
import { Animations } from '@qwyk/ui-standalone';
import { Portals } from '@qwyk/models';
import { BookingWizardConfig } from './../../bookingWizardConfig';
@Component({
    selector: 'qwyk-offer-list',
    templateUrl: './offer-list.component.html',
    styleUrls: ['./offer-list.component.scss'],
    animations: [Animations.fadeInAnimation],
})
export class OfferListComponent implements OnDestroy {
    private _sortOrder = '-total_amount';
    private sortOrderSubject: BehaviorSubject<string> = new BehaviorSubject(
        this._sortOrder
    );

    public quotationRequest$ = this.bookingWizard.quotationRequest$;
    public offers$ = this.sortOrderSubject.pipe(
        switchMap(order =>
            this.bookingWizard.allOffers$.pipe(
                map(e => this.setQuotationSortOrder(e, order))
            )
        )
    );
    public polling$ = this.bookingWizard.polling$;
    public loaded$ = this.bookingWizard.loaded$;
    public resultsPage = 1;
    public resultsPageSize = 5;

    constructor(
        private bookingWizard: BookingWizardOffersFacade,
        public config: BookingWizardConfig
    ) {}

    ngOnDestroy() {
        this.bookingWizard.abortPolling();
        this.bookingWizard.reset();
    }

    public set sortOrder(sortOrder: string) {
        this._sortOrder = sortOrder;
        this.sortOrderSubject.next(sortOrder);
    }

    public get sortOrder(): string {
        return this._sortOrder;
    }

    private setQuotationSortOrder(
        offers: Portals.QuotationOffer[],
        sortOrder: string
    ): Portals.QuotationOffer[] {
        offers.sort((a, b) => {
            const direction = sortOrder.substr(0, 1);
            const key = sortOrder.substr(1, sortOrder.length - 1);
            const one = direction === '-' ? a[key] : b[key];
            const two = direction === '-' ? b[key] : a[key];
            if (two == null) {
                return -1;
            }

            if (one == null) {
                return 1;
            }

            return one > two ? 1 : -1;
        });

        return offers;
    }
}
