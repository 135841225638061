<ng-container *ngIf="quotationRequest$ | async; let quotation">
    <div class="row justify-content-center mx-0">
        <div class="col-12 col-lg-8 col-xl-7 col-xxl-6 col-xxxl-5" style="max-width: 680px; min-width: 525px;">
            <h2 class="font-weight-normal">Offers</h2>
            <h6 class="font-weight-light">
                Choose one of the offers listed below, pick a schedule and click Book to
                continue.
            </h6>
            <hr>
            <div class="card border-0 shadow text-dark">
                <div class="d-flex flex-row">
                    <div class="card-body flex-grow-1">
                        <div class="font-weight-bold">
                            {{ quotation.request.origin.name }} -
                            {{ quotation.request.destination.name }}
                        </div>
                        <div *ngFor="let pkg of quotation.request.packages">
                            {{ pkg.quantity }} x {{ pkg.packaging.description }} |
                            {{ quotation.request.commodity.description }}
                        </div>
                    </div>
                    <div class="card-body flex-grow-0">
                        <button class="btn btn-secondary rounded-circle shadow" style="width: 48px; height: 48px;"
                            ngbTooltip="Restart" [routerLink]="['/my-portal/booking-wizard']">
                            <fa-icon [icon]="['far', (polling$ | async) ? 'spinner' : 'undo-alt']" size="lg"
                                [pulse]="polling$ | async"></fa-icon>
                        </button>
                    </div>
                </div>
                <div class="card-body d-flex flex-row justify-content-between align-items-center border-top py-2">
                    <div class="small text-muted">
                        {{ (offers$ | async).length }} offer(s)
                    </div>
                    <div>
                        <div class="input-group input-group-sm">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <fa-icon [icon]="['far', 'sort-amount-down']"></fa-icon>
                                </span>
                            </div>
                            <select class="custom-select custom-select-sm" [(ngModel)]="sortOrder">
                                <option value="-total_amount">Price [Cheapest]</option>
                                <option value="+total_amount">Price [Most Expensive]</option>
                                <option value="-transit_time">Transit time [Fastest]</option>
                                <option value="+transit_time">Transit time [Slowest]</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="alert alert-success mb-0" *ngIf="quotation.is_demo">
                    <div class="font-weight-bold">These are demo rates.</div>
                    <div class="small">Log in with your personal account to get custom rates, and to continue booking.
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="offers$ | async; let offers;">

        <div class="row justify-content-center pt-3">
            <div class="col-12 col-lg-8 col-xl-7 col-xxl-6 col-xxxl-5" style="max-width: 680px;"
                [ngClass]="{'offset-xxl-3': !(offers.length === 0 && (polling$ | async) === false) && !quotation.is_demo}">
                <ng-container *ngIf="offers.length > resultsPageSize">
                    <div class="small text-muted text-right">{{(resultsPage-1) * resultsPageSize + 1}} to {{
                        resultsPage *
                        resultsPageSize }} of {{offers.length}}</div>
                </ng-container>


                <qwyk-quotation-offer-list-item
                    *ngFor="let offer of offers  | slice: (resultsPage-1) * resultsPageSize : resultsPage * resultsPageSize"
                    [offer]="offer" [quotation]="quotation" [@fadeIn] [allowBooking]="!quotation.is_demo">
                </qwyk-quotation-offer-list-item>
                <ngb-pagination *ngIf="offers.length > resultsPageSize" [(page)]="resultsPage" size="sm"
                    class="d-flex justify-content-center" [pageSize]="resultsPageSize" [maxSize]="5" [rotate]="true"
                    [ellipses]="false" [boundaryLinks]="true" [collectionSize]="offers.length"></ngb-pagination>
                <div *ngIf="polling$ | async" class="my-3">
                    <qwyk-loading-indicator
                        [textClass]="'text-primary font-weight-light mb-0' + (!config.wrap ? ' text-light': '')"
                        [typing]="true" size="lg"
                        [text]="'We are gathering ' + (offers.length > 0 ? 'additional ' : '') + ' offers'"
                        spinner="spinner-third"
                        subText="We're finding offers at multiple sources, this may take a moment.">
                    </qwyk-loading-indicator>
                </div>
                <div *ngIf="(polling$ | async) === false && offers.length === 0" [@fadeIn] class="my-3 text-center p-3">
                    <h5 class="font-weight-light">We're sorry but we weren't able to find any rates for your request
                    </h5>
                    <p>
                        Although we couldn't give you an automated rate, we might still be able to quote you one. Please
                        fill
                        out this form and someone will get back to you as soon as possible.
                    </p>
                    <div class="card text-dark" *ngIf="!quotation.is_demo">
                        <div class="card-body">
                            <qwyk-request-for-quotation-offer [quotation]="quotation" [noRatesReason]="true">
                            </qwyk-request-for-quotation-offer>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-8 col-xl-7 col-xxl-3 border-xxl-start"
                *ngIf="!(offers.length === 0 && (polling$ | async)===false) && !quotation.is_demo" [@fadeIn]>
                <div [ngClass]="{'card text-dark shadow': !config.wrap}">
                    <div [ngClass]="{'card-body': !config.wrap}">
                        <qwyk-request-for-quotation-offer [quotation]="quotation">
                        </qwyk-request-for-quotation-offer>
                        <hr>
                        <h5 class="font-weight-normal">Not ready to book yet?</h5>
                        <p [ngClass]="{'text-muted': config.wrap}">You can retrieve this quotation later in your <a
                                [routerLink]="['/my-portal/quotations']" class="text-secondary">Quotations List</a> and
                            continue
                            where you left of.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
