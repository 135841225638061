import {TranslateLoader} from "@ngx-translate/core";
import {HttpLoaderFactory} from "./http-loader.factory";
import {HttpClient} from "@angular/common/http";
import {TranslateModuleConfig} from "@ngx-translate/core";
import {PortalsTranslationConfig} from "./portals-translation.module";
import {environment} from "@qwyk/portals/environment";

export const TranslateConfigRoot: TranslateModuleConfig = {
    loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient, PortalsTranslationConfig]
    },
    defaultLanguage: environment.translation.defaultLanguage
};

export const TranslateConfigChild: TranslateModuleConfig = {
    extend: true,
    loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient, PortalsTranslationConfig]
    }
};
