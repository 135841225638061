import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

import { MomentModule } from 'ngx-moment';
import { TranslateModule } from '@ngx-translate/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { ShipmentActivitiesListItemComponent } from '../shipment-activities-list-item/shipment-activities-list-item.component';
import { NgIf } from '@angular/common';

@Component({
    standalone: true,
    imports: [NgIf, RouterLink, MomentModule, FontAwesomeModule, NgbTooltipModule, TranslateModule],
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'li[qwyk-shipment-activities-status-update]',
    templateUrl: './shipment-activities-status-update.component.html',
    styleUrls: ['./shipment-activities-status-update.component.scss'],
})
export class ShipmentActivitiesStatusUpdateComponent extends ShipmentActivitiesListItemComponent {}
