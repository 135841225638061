<div class="h-100 d-flex align-items-center">
    <img class="rounded-circle border mr-3" style="width: 40px; height: 40px;" alt="Avatar"
        [src]="'https://www.gravatar.com/avatar/' + (conversation.customer.email | md5) + '?d=https%3A%2F%2Fui-avatars.com%2Fapi%2F/' + conversation.customer.name.replace(' ', '+') + '/48/097aff/ffffff/2//false/true/true'">
</div>
<div class="flex-fill overflow-hidden">
    <div class="font-weight-bold">{{ conversation.customer.name }}</div>
    <div class="text-truncate text-nowrap">{{ conversation.subject }}</div>
</div>
<div class="ml-2 flex-shrink-0 small">
    {{ conversation.updated_at | amCalendar }}
</div>
