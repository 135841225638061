<link [attr.href]="designCss" rel="stylesheet">
<router-outlet></router-outlet>
<div *ngIf="(loaderService.shown$ | async) === true" class="global-loader">
    <div class="global-loader-inner text-white text-center">
        <fa-icon [icon]="['far', 'spinner']" animation="spin-pulse"></fa-icon>
        <label *ngIf="loaderService.message$ | async; let message">
            {{ message }}
        </label>
    </div>
</div>
