import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { DropdownModule } from 'primeng/dropdown';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { LanguagePickerComponent } from './components';
import { PortalsTranslateService } from './portals-translate.service';

export class PortalsTranslationConfig {
    translationsDeployUrl: string;
}

@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        FormsModule,
        DropdownModule,
        FontAwesomeModule,
        TranslateModule.forChild({ extend: true }),
    ],
    declarations: [LanguagePickerComponent],
    providers: [PortalsTranslateService],
    exports: [LanguagePickerComponent],
})
export class PortalsTranslationModule {
    static forRoot(
        config: PortalsTranslationConfig
    ): ModuleWithProviders<PortalsTranslationModule> {
        return {
            ngModule: PortalsTranslationModule,
            providers: [
                { provide: PortalsTranslationConfig, useValue: config },
            ],
        };
    }
}
