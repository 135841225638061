/* eslint-disable @typescript-eslint/no-explicit-any */
import { Directive, TemplateRef } from '@angular/core';

@Directive({
    standalone: true,
    selector: '[qwykLocationTypeaheadItem]',
})
export class LocationTypeaheadItemDirective {
    constructor(public template: TemplateRef<any>) {}
}

@Directive({
    standalone: true,
    selector: '[qwykLocationTypeaheadPlaceholder]',
})
export class LocationTypeaheadPlaceholderDirective {
    constructor(public template: TemplateRef<any>) {}
}

@Directive({
    standalone: true,
    selector: '[qwykLocationTypeaheadHint]',
})
export class LocationTypeaheadHintDirective {
    constructor(public template: TemplateRef<any>) {}
}

@Directive({
    standalone: true,
    selector: '[qwykLocationTypeaheadNotFound]',
})
export class LocationTypeaheadNotFoundDirective {
    constructor(public template: TemplateRef<any>) {}
}
