import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, ReactiveFormsModule } from '@angular/forms';

import {
    Validators,
    ReactiveValidationModule,
} from 'angular-reactive-validation';
import { TranslateModule } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,
        ReactiveFormsModule,
        ReactiveValidationModule,
    ],
    selector: 'qwyk-create-custom-view-modal',
    templateUrl: './create-custom-view-modal.component.html',
    styleUrls: ['./create-custom-view-modal.component.scss'],
})
export class CreateCustomViewModalComponent implements OnInit {
    public dismissAction = 'common.cancel';
    public confirmAction = 'common.save';
    public confirmStyle = 'btn-primary';
    public dismissStyle = null;
    public inputValue = null;
    public form!: FormGroup;

    constructor(private modal: NgbActiveModal) {}

    ngOnInit() {
        this.form = new FormGroup({
            name: new FormControl('', [
                Validators.required(),
                Validators.pattern(/^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚ ]+$/),
            ]),
        });
    }

    public confirm() {
        const { name } = this.form.value;
        this.modal.close(name);
    }

    public dismiss() {
        this.modal.dismiss();
    }
}
