import { Component, inject, Input } from '@angular/core';

import {Portals} from '@qwyk/models';
import { AuthenticationFacade } from '@qwyk/portals/authentication';

import {QUOTATION_STATUS_ICONS, QUOTATION_STATUS_LABELS} from '../../data';

@Component({
    selector: 'qwyk-quotations-index-table',
    templateUrl: './quotations-index-table.component.html',
    styleUrls: ['./quotations-index-table.component.scss']
})
export class QuotationsIndexTableComponent {

    private auth = inject(AuthenticationFacade);

    @Input() quotations: Portals.QuotationEntity[];
    @Input() loading: boolean;

    public readonly icons = QUOTATION_STATUS_ICONS;
    public readonly labels = QUOTATION_STATUS_LABELS;

    public dateFormat$ = this.auth.dateFormat$;
}
