import { Component, inject } from '@angular/core';
import { SiteConfigFacade } from '@qwyk/portals/siteconfig';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export interface RequestedNavigation {
    component: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data?: any;
}

@Component({
    selector: 'qwyk-login-modal',
    templateUrl: './login-modal.component.html',
    styleUrls: ['./login-modal.component.scss'],
})
export class LoginModalComponent {

    private ngbModal = inject(NgbActiveModal);
    private siteConfig = inject(SiteConfigFacade);

    design$ = this.siteConfig.design$;
    authenticationType$ = this.siteConfig.authenticationType$;
    isMagayaNetwork$ = this.siteConfig.isMagayaNetwork$;
    component = 'login';
    navigationData = null;
    allowContinueWithoutLogin = false;
    siteConfiguration$ = this.siteConfig.config$;

    closeDialog() {
        this.ngbModal.close();
    }

    cancelDialog(reason?) {
        this.ngbModal.dismiss(reason);
    }

    onNavigationRequested(navigation: RequestedNavigation) {
        this.navigationData = navigation.data;
        this.component = navigation.component;
    }
}
