<a
    (click)="$event.stopPropagation(); op.toggle($event)"
    class="ml-4 btn btn-sm btn-shipment-subscription"
>
    <fa-icon
        [icon]="['far', is_customer_user_subscribed ? 'bell-slash' : 'bell-plus']"
        [style]="{cursor: 'pointer'}"
    />
    {{ (is_customer_user_subscribed ? 'portal.shipment.subscription.unsubscribe' : 'portal.shipment.subscription.subscribe') | translate }}
</a>
<p-overlayPanel #op>
    <form [formGroup]="form">
        <div class="form-group row">
            <div class="col-8">
                <label for="shipment_activity_notification">
                    {{ 'portal.shipment.subscription.receive-shipment-activity-notification' | translate }}
                </label>
                <br>
                <small class="text-warning" *ngIf="!shipmentActivityNotificationEnabled">
                    <a routerLink="/settings/notifications">
                        {{ 'portal.shipment.subscription.please-enable-in-global-settings' | translate }}
                    </a>
                </small>
            </div>
            <div class="col-4 text-right">
                <p-inputSwitch
                    id="shipment_activity_notification"
                    formControlName="shipment_activity_notification"
                ></p-inputSwitch>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-8">
                <label for="shipment_status_notification">
                    {{ 'portal.shipment.subscription.receive-shipment-status-notification'| translate }}
                </label>
                <br>
                <small class="text-warning" *ngIf="!shipmentStatusNotificationEnabled">
                    <a routerLink="/settings/notifications">
                        {{ 'portal.shipment.subscription.please-enable-in-global-settings' | translate }}
                    </a>
                </small>
            </div>
            <div class="col-4 text-right">
                <p-inputSwitch
                    id="shipment_status_notification"
                    formControlName="shipment_status_notification"
                ></p-inputSwitch>
            </div>

        </div>
        <div class="row">
            <div class="col p-overlaypanel-footer">
                <button class="btn btn-primary mr-2" (click)="submit(); op.hide();">
                    {{ 'common.save' | translate }}
                </button>
                <button class="btn btn-light" (click)="resetForm(); op.hide(); ">
                    {{ 'common.cancel' | translate }}
                </button>
            </div>
        </div>
    </form>
</p-overlayPanel>
