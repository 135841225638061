import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'slugToTitle',
    standalone: true,
})
export class SlugToTitlePipe implements PipeTransform {
    transform(value: string): string {
        if (!value) {
            return value;
        }
        let split: string[] = value.split(/-|_/);
        split = split.map(word => {
            word = word.toLowerCase();
            if (word.length > 0) {
                word = word.charAt(0).toUpperCase() + word.slice(1);
            }
            return word;
        });
        return split.join(' ');
    }
}
