import { Component, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'qw-four-ofour',
    templateUrl: './four-ofour.component.html',
    styleUrls: ['./four-ofour.component.scss'],
})
export class FourOFourComponent {
    private title = inject(Title);
    private route = inject(ActivatedRoute);

    public queryParams$ = this.route.queryParamMap;

    constructor() {
        this.title.setTitle('This portal is unavailable');
    }
}
