import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';

import {
    SharedStoresShipmentContainersModule,
    ShipmentContainersServiceBase,
} from '@qwyk/shared-stores/shipment-containers';
import { ShipmentContainersListComponent } from '@qwyk/ui-standalone';

import { ShipmentContainersViewComponent } from './components';
import { ShipmentContainersService } from './shipment-containers.service';

@NgModule({
    imports: [
        CommonModule,
        ShipmentContainersListComponent,
        SharedStoresShipmentContainersModule.forFeature({
            service: {
                provide: ShipmentContainersServiceBase,
                useFactory: http => new ShipmentContainersService(http),
                deps: [HttpClient],
            },
        }),
    ],
    declarations: [ShipmentContainersViewComponent],
    exports: [ShipmentContainersViewComponent],
})
export class PortalsShipmentContainersModule {}
