import { inject, Injectable } from '@angular/core';

import Echo from 'laravel-echo';

import { defer, Observable, Subject } from 'rxjs';

import { environment } from '@qwyk/portals/environment';

import { NotificationsFacade } from './state/notifications.facade';
import { NotificationsEntity } from './state/notifications.models';

@Injectable({
    providedIn: 'root',
})
export class PortalsSocketNotificationsService {

    private readonly notificationsFacade: NotificationsFacade = inject(NotificationsFacade);

    private initialized = false;
    private connected = false;
    private userId = null;
    private privateChannel = null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private echo!: Echo<any>;

    public registerEchoListener(
        token: string,
        userId: string
    ): Observable<NotificationsEntity> {
        if (this.initialized) {
            this.deregisterEchoListener();
        }

        const value = new Subject<NotificationsEntity>();
        const observable = value.asObservable();

        this.userId = userId;

        this.privateChannel = `${environment.echoConfig.userModel}.${this.userId}`;
        this.echo = new Echo({
            broadcaster: 'pusher',
            key: environment.echoConfig.options.key,
            cluster: environment.echoConfig.options.cluster,
            authEndpoint: environment.echoConfig.options.authEndpoint,
            auth: {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            },
            forceTLS: environment.echoConfig.options.forceTLS,
            disableStats: true,
            enabledTransports: environment.echoConfig.options.enabledTransports
        });

        const observer = defer(async () => {
            await this.echo.private(this.privateChannel).notification(notification => {
                value.next(notification);
            });
            this.initialized = true;
        });

        observer.subscribe();

        this.subscribeEchoConnectionState();
        return observable;
    }

    public deregisterEchoListener() {
        if (!this.echo) {
            return;
        }

        this.initialized = false;
        this.echo.leave(
            `private-${environment.echoConfig.userModel}.${this.userId}`
        );
        this.echo.disconnect();
        this.connected = false;
    }

    private subscribeEchoConnectionState() {
        this.echo.connector.pusher.connection.bind('disconnected', (state) => {
            if (!state && this.initialized && this.connected) {
                this.notificationsFacade.reinitializeEchoListener();
            }
        });
    }
}
