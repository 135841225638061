import { Component, inject, Input } from '@angular/core';

import { Portals } from '@qwyk/models';
import { AuthenticationFacade } from '@qwyk/portals/authentication';

@Component({
    selector: 'qwyk-quotation-rate-request-list',
    templateUrl: './quotation-rate-request-list.component.html',
    styleUrls: ['./quotation-rate-request-list.component.scss']
})
export class QuotationRateRequestListComponent {

    private readonly auth = inject(AuthenticationFacade);

    @Input() quotation: Portals.QuotationEntity;

    public dateFormat$ = this.auth.dateFormat$;
}
