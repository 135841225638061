import { Pipe, PipeTransform } from '@angular/core';
import { IconName } from '@fortawesome/fontawesome-svg-core';

@Pipe({
    standalone: true,
    name: 'valueMap',
})
export class ValueMapPipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transform(value: string, map: any, defaultValue?: string): IconName {
        if (!value || !map) {
            return (defaultValue || value) as IconName;
        }

        // eslint-disable-next-line no-prototype-builtins
        if (map.hasOwnProperty(value)) {
            return map[value] as IconName;
        }

        return (defaultValue || value) as IconName;
    }
}
