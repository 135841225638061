import { Component, Input } from '@angular/core';
import {
    DecimalPipe,
    NgClass,
    NgFor,
    NgIf,
    NgSwitch, NgSwitchCase,
    NgSwitchDefault,
    NgTemplateOutlet,
    TitleCasePipe,
    UpperCasePipe,
} from '@angular/common';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { Portals } from '@qwyk/models';
import { MomentModule } from 'ngx-moment';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { ObjectToKVPPipe, SlugToTitlePipe, TransportModeIconPipe } from '../../pipes';

@Component({
    standalone: true,
    imports: [NgIf, NgClass, NgFor, NgSwitch, NgSwitchDefault, NgSwitchCase, SlugToTitlePipe, ObjectToKVPPipe, DecimalPipe, TransportModeIconPipe, NgTemplateOutlet, NgbTooltipModule, UpperCasePipe, TitleCasePipe, MomentModule, FontAwesomeModule],
    selector: 'qwyk-quotation-request-summary',
    templateUrl: './quotation-request-summary.component.html',
    styleUrls: ['./quotation-request-summary.component.scss'],
})
export class QuotationRequestSummaryComponent {
    @Input() quotation: Portals.QuotationEntity;
    @Input() rowBreak = 'col-12';
    @Input() wrapInCard = false;

    get request() {
        return this.quotation.request;
    }
}
