<div class="d-flex align-items-center">
    <div>
        <fa-icon [fixedWidth]="true"
                 [icon]="['far', notification.notification_type | notificationIcon: notification.notification_sub_type]"
                 class="text-primary mr-4" size="lg">
        </fa-icon>
    </div>

    <div class="flex-grow-1">
        <div class="text-dark overflow-hidden text-nowrap text-truncate">
            <a [ngClass]="{'font-weight-normal': notification.read}" class="h6 mb-1 d-inline-block text-dark"
               href="javascript:void(0);">
                {{ notification.subject }}
            </a>
        </div>
        <div class="text-muted">
            <span [ngbTooltip]="notification.received_at | amLocal | amDateFormat: 'LLL'" placement="bottom"
                  style="cursor: pointer;">
                {{ 'common.received' | translate }} {{ notification.received_at | amTimeAgo }}
            </span>
            <ng-container [ngSwitch]="notification.entity.type">
                <ng-container *ngSwitchCase="'App\\Models\\Portals\\Shipments\\Shipment'">
                    &bull;
                    <a [routerLink]="['/my-portal/shipments/', notification.entity.id]">
                        {{ notification.entity.title }}
                    </a>
                </ng-container>
                <ng-container *ngSwitchCase="'App\\Domain\\Shipment\\Models\\Shipment'">
                    &bull;
                    <a [routerLink]="['/my-portal/shipments/', notification.entity.id]">
                        {{ notification.entity.title }}
                    </a>
                </ng-container>
                <ng-container *ngSwitchCase="'App\\Models\\Portals\\Quotations\\Quotation'">
                    &bull;
                    <a [routerLink]="['/my-portal/quotations/', notification.entity.id]">
                        {{ notification.entity.title }}
                    </a>
                </ng-container>
                <ng-container *ngSwitchCase="'App\\Models\\Portals\\Schedules\\SavedSchedule'">
                    &bull;
                    <a [routerLink]="['/my-portal/schedules/saved/', notification.entity.id]">
                        {{ notification.entity.title }}
                    </a>
                </ng-container>
            </ng-container>
        </div>
    </div>

    <div #myDrop="ngbDropdown" class="d-inline-block" container="body" ngbDropdown placement="bottom-end">
        <a (click)="onDropdownClick($event, myDrop)" [ngClass]="{'rotate': myDrop.isOpen()}" class="options-dd-toggle ml-1 text-muted" container="body"
           href="javascript:void(0)" ngbDropdownAnchor>
            <fa-icon [fixedWidth]="true" [icon]="['far', 'ellipsis-v']" class="d-inline-block" size="lg"></fa-icon>
        </a>
        <div aria-labelledby="dropdown" class="p-0" ngbDropdownMenu style="min-width: 200px;">
            <button (click)="onToggleRead($event)" ngbDropdownItem>
                <fa-icon [icon]="['far', notification.read ? 'envelope-open' : 'envelope']"></fa-icon>
                {{ 'portal.notifications.mark-as' | translate }} {{ (notification.read ? 'common.unread' : 'common.read') | translate }}
            </button>
            <button (click)="onDelete($event)" ngbDropdownItem>
                <fa-icon [icon]="['far', 'trash']"></fa-icon>
                {{ 'common.delete' | translate }}
            </button>
        </div>
    </div>
</div>

<div *ngIf="false" [ngClass]="{'bg-light': expanded}" class="notification-wrapper d-flex align-items-center p-1">
    <fa-icon [fixedWidth]="true"
             [icon]="['far', notification.notification_type | notificationIcon: notification.notification_sub_type]"
             class="text-muted mr-4" size="lg">
    </fa-icon>
    <div class="flex-grow-1">
        <div class="d-flex justify-content-between">
            <div [ngClass]="{'font-weight-bold': !notification.read}" class="subject">{{notification.subject}}</div>
            <div class="text-muted small">{{ notification.received_at | amTimeAgo }}</div>
        </div>
        <ng-container [ngSwitch]="notification.entity.type">
            <a *ngSwitchCase="'App\\Models\\Portals\\Shipments\\Shipment'"
               [routerLink]="['/my-portal/shipments/', notification.entity.id]">{{ notification.entity.title }}</a>
            <a *ngSwitchCase="'App\\Domain\\Shipment\\Models\\Shipment'"
               [routerLink]="['/my-portal/shipments/', notification.entity.id]">{{ notification.entity.title }}</a>
            <a *ngSwitchCase="'App\\Models\\Portals\\Quotations\\Quotation'"
               [routerLink]="['/my-portal/quotations/', notification.entity.id]">{{ notification.entity.title }}</a>
            <a *ngSwitchCase="'App\\Models\\Portals\\Schedules\\SavedSchedule'"
               [routerLink]="['/my-portal/schedules/saved/', notification.entity.id]">{{ notification.entity.title }}</a>
        </ng-container>
    </div>

    <div *ngIf="hover" class="actions bg-light d-flex align-items-center">
        <button (click)="onToggleRead($event)" ngbTooltip="{{ 'portal.notifications.mark-as' | translate }} {{ (notification.read ? 'common.unread': 'common.read') | translate }}"
                class="btn btn-icon" placement="bottom">
            <fa-icon [icon]="['far', notification.read ? 'envelope-open' : 'envelope']"></fa-icon>
        </button>
        <button (click)="onDelete($event)" class="btn btn-icon" ngbTooltip="{{ 'common.delete' | translate }}" placement="bottom">
            <fa-icon [icon]="['far', 'trash']"></fa-icon>
        </button>
    </div>
</div>

<qwyk-notification-detail *ngIf="expanded" [notification]="notification"></qwyk-notification-detail>
