import { Pipe, PipeTransform } from '@angular/core';
import { IconName } from '@fortawesome/fontawesome-svg-core';

@Pipe({
    standalone: true,
    name: 'notificationIcon',
})
export class NotificationIconPipe implements PipeTransform {
    icons: any = {
        'quotation-rate-posted': 'hand-holding-usd',
        'quotation-published': 'hands-usd',
        'document': 'file',
        'private-message': 'envelope',
        'shipment-status-update': 'clock',
        'comment': 'comment',
        //follow: 'follow',
        'shipment-activity': {
            'milestone-change': 'calendar-edit',
            'comment': 'comment',
            'schedule-change': 'ship',
            'document': 'file',
        },
        'entity-shared': 'user-plus',
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transform(value: string, ...args: any[]): IconName {
        let icon = this.icons[value];

        if (
            icon && typeof icon === 'object' && args.length > 0 &&
            args[0] !== null
        ) {
            icon = icon[args[0]];
        }

        if (!icon) {
            icon = 'envelope';
        }
        return icon as IconName;
    }
}

