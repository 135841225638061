import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ui } from '@qwyk/models';

import { Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import { EnvironmentConfig } from '../environmentConfig';

@Injectable({
    providedIn: 'root',
})
export class GooglePlacesService {
    // autocompleteService: google.maps.places.AutocompleteService;
    // placesService: google.maps.places.PlacesService;
    private environment;

    constructor(env: EnvironmentConfig, private http: HttpClient) {
        this.environment = env;
        // this.autocompleteService = new google.maps.places.AutocompleteService();
        // this.placesService = new google.maps.places.PlacesService(document.getElementById('places-service') as HTMLDivElement);
    }

    private static getFormattedAddress(place: any): ui.GooglePlacesAddress {
        const location: ui.GooglePlacesAddress = {
            formattedAddress: place.formatted_address || '',
            streetNumber: '',
            route: '',
            locality: '',
            postalCode: '',
            state: '',
            countryName: '',
            countryCode: '',
            position: {
                lat: place.geometry?.location?.lat || 0,
                lng: place.geometry?.location?.lng || 0,
            },
            types: place.types || [],
            code: '',
            placeId: place.place_id || '',
            plusCode: place.plus_code?.global_code || '',
        };

        place.address_components.forEach((item: any) => {
            const componentType = item.types[0];

            switch (componentType) {
                case 'locality':
                case 'sublocality':
                case 'postal_town':
                    if (!location.locality) { location.locality = item.long_name; }
                    if (!location.code) { location.code = item.short_name; }
                    break;

                case 'administrative_area_level_1':
                    location.state = item.short_name;
                    break;

                case 'street_number':
                    location.streetNumber = item.short_name;
                    break;

                case 'route':
                    location.route = item.long_name;
                    break;

                case 'country':
                    location.countryName = item.long_name;
                    location.countryCode = item.short_name;
                    break;

                case 'postal_code':
                    location.postalCode = item.short_name;
                    if (!location.code) { location.code = item.short_name; }
                    break;

                default:
                    break;
            }
        });

        return location;
    }

    public getSuggestions(
        term: string,
        types?: string
    ): Observable<google.maps.places.AutocompletePrediction[] | null> {
        if (!term) {
            return of(null);
        }
        const params: { types: string } = { types: '' };
        if (types) {
            params['types'] = types;
        }

        return this.http
            .post<google.maps.places.AutocompletePrediction[]>(
                `${this.environment.backendServer}/api/masters/gplaces/autocomplete?term=${term}`,
                { term, params }
            )
            .pipe(
                take(1),
                catchError(() => of([]))
            );
    }

    public getPlaceDetails(
        placeId: string
    ): Observable<google.maps.places.PlaceResult> {
        return this.http
            .get<google.maps.places.PlaceResult>(
                `${this.environment.backendServer}/api/masters/gplaces/place/${placeId}`
            )
            .pipe(
                take(1),
                catchError(() => of({}))
            );
    }

    public getParsedPlaceDetails(
        placeId: string
    ): Observable<ui.GooglePlacesAddress | null> {
        return this.getPlaceDetails(placeId).pipe(
            take(1),
            map(result => {
                if (!result) {
                    return null;
                } else {
                    return GooglePlacesService.getFormattedAddress(result);
                }
            })
        );
    }
}
