/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Observable, of } from 'rxjs';
import { map, shareReplay, first, take, switchMap } from 'rxjs/operators';
import {
    NgbCalendar,
    NgbDateStruct,
    NgbDateAdapter,
    NgbDateNativeAdapter,
    NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import { Animations } from '@qwyk/ui-standalone';
import {
    FormGroup,
    FormBuilder,
    Validators,
    FormArray,
    UntypedFormArray,
} from '@angular/forms';
import { MasterDataService } from '@qwyk/core';
import { QuotationsService } from '../../services/quotations.service';
import {
    AuthenticationFacade,
    LoginModalComponent,
} from '@qwyk/portals/authentication';
import { SiteConfigFacade } from '@qwyk/portals/siteconfig';

@Component({
    selector: 'qwyk-enter-cargo-details',
    templateUrl: './enter-cargo-details.component.html',
    styleUrls: ['./enter-cargo-details.component.scss'],
    animations: [Animations.fadeInAnimation],
    providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }],
})
export class EnterCargoDetailsComponent implements OnInit, OnDestroy {
    dimensionsInput = true;
    minCargoReadyDate = this.calendar.getToday();
    cargoReady: NgbDateStruct = null;
    commodities$: Observable<any[]>;
    packagings$: Observable<any[]>;
    currencies$: Observable<any[]>;
    busy = false;
    form: FormGroup;
    error: any = null;
    authenticated$ = this.authentication.authenticated$;

    private queryParamsSubscription: Subscription;
    constructor(
        private route: ActivatedRoute,
        private calendar: NgbCalendar,
        private fb: FormBuilder,
        private masterData: MasterDataService,
        private router: Router,
        private service: QuotationsService,
        private authentication: AuthenticationFacade,
        private modalService: NgbModal,
        private siteConfig: SiteConfigFacade
    ) {}

    ngOnInit() {
        this.queryParamsSubscription = this.route.queryParams
            .pipe(
                switchMap(params => {
                    if (params.copy) {
                        return this.service
                            .getQuotation(params.copy, false, null)
                            .pipe(
                                map(quotation => ({
                                    ...quotation.request,
                                    immediate: params.immediate,
                                }))
                            );
                    } else {
                        return of({
                            transport_mode: params.transportMode,
                            load_type: params.loadType,
                            origin: {
                                type: params.origin_type,
                                code: params.origin_code,
                                locode: params.origin_locode,
                                name: params.origin_name,
                                country_code: params.origin_country,
                            },
                            destination: {
                                type: params.destination_type,
                                code: params.destination_code,
                                locode: params.destination_locode,
                                name: params.destination_name,
                                country_code: params.destination_country,
                            },
                            require_origin_charges: params.require_oc
                                ? params.require_oc === 'true'
                                : true,
                            require_destination_charges: params.require_dc
                                ? params.require_dc === 'true'
                                : params.destination_type !== 'port',
                            commodity: null,
                            currency: null,
                            additionals: {
                                customs_clearance_origin: false,
                                customs_clearance_destination: false,
                                cargo_insurance: false,
                                hazardous_cargo: false,
                                liftgate_pickup: false,
                                refrigerated: false,
                                white_glove: false,
                                residential_delivery: false,
                                residential_pickup: false,
                                liftgate_delivery: false,
                                call_before_delivery: false,
                                delivery_appointment_required: false,
                                flatbed_required: false,
                                limited_access_delivery: false,
                                limited_access_pickup: false,
                                stackable: false,
                            },
                            packages: [],
                            immediate: false,
                        });
                    }
                })
            )
            .subscribe(input => {
                this.form = this.fb.group({
                    header: this.fb.group({
                        wantsQuote: [{ value: true, disabled: true }],
                        owner_reference: [null, [Validators.maxLength(255)]],
                        load_type: {
                            value: input.load_type,
                            disabled: true,
                        },
                        transport_mode: {
                            value: input.transport_mode,
                            disabled: true,
                        },
                        origin: {
                            value: input.origin,
                            disabled: true,
                        },
                        destination: {
                            value: input.destination,
                            disabled: true,
                        },
                        require_origin_charges: {
                            value: input.require_origin_charges,
                            disabled: true,
                        },
                        require_destination_charges: {
                            value: input.require_destination_charges,
                            disabled: true,
                        },
                        commodity: [input.commodity, [Validators.required]],
                        cargo_ready: [new Date(), [Validators.required]],
                        currency: [input.currency, [Validators.required]],
                    }),
                    packages:
                        input.load_type === 'fcl'
                            ? this.makeFclCargoForm(input.packages)
                            : this.makeLclCargoForm(input.packages),
                    additionals: this.fb.group({
                        customs_clearance_origin: [
                            input.additionals.customs_clearance_origin,
                        ],
                        customs_clearance_destination: [
                            input.additionals.customs_clearance_destination ??
                                input.additionals.customs_clearance,
                        ],
                        cargo_insurance: [input.additionals.cargo_insurance],
                        hazardous_cargo: [input.additionals.hazardous_cargo],
                        liftgate_pickup: [input.additionals.liftgate_pickup],
                        refrigerated: [input.additionals.refrigerated],
                        white_glove: [input.additionals.white_glove],
                        residential_delivery: [
                            input.additionals.residential_delivery,
                        ],
                        residential_pickup: [
                            input.additionals.residential_pickup,
                        ],
                        liftgate_delivery: [
                            input.additionals.liftgate_delivery,
                        ],
                        call_before_delivery: [
                            input.additionals.call_before_delivery,
                        ],
                        delivery_appointment_required: [
                            input.additionals.delivery_appointment_required,
                        ],
                        flatbed_required: [input.additionals.flatbed_required],
                        limited_access_delivery: [
                            input.additionals.limited_access_delivery,
                        ],
                        limited_access_pickup: [
                            input.additionals.limited_access_pickup,
                        ],
                        stackable: [input.additionals.stackable],
                    }),
                });

                if (input.immediate) {
                    this.next();
                }

                this.packagings$ = this.masterData.getPackagings(
                    input.transport_mode,
                    input.load_type,
                    true
                );
            });

        this.commodities$ = this.masterData.getCommodities().pipe(
            map(data =>
                data
                    .map(e => {
                        e['group'] = e.is_preferred ? undefined : '-';
                        return e;
                    })
                    .sort((a, b) => (a.is_preferred > b.is_preferred ? -1 : 1))
            ),
            shareReplay()
        );

        this.currencies$ = this.masterData.getCurrencies().pipe(
            map(data =>
                data
                    .map(e => {
                        e['group'] = e.is_preferred ? undefined : '-';
                        return e;
                    })
                    .sort((a, b) => (a.is_preferred > b.is_preferred ? -1 : 1))
            ),
            shareReplay()
        );
    }
    private makeLclCargoForm(packages): FormArray {
        const array = new UntypedFormArray([], [Validators.minLength(1)]);

        if (packages && packages.length > 0) {
            for (const pkg of packages) {
                const row = this.makeLclCargoRow();
                row.patchValue(pkg);
                array.push(row);
            }
        } else {
            array.push(this.makeLclCargoRow());
        }

        return array;
    }
    private makeFclCargoForm(packages): FormArray {
        const array = new UntypedFormArray(
            [],
            [Validators.minLength(1), Validators.maxLength(1)]
        );

        if (packages && packages.length > 0) {
            for (const pkg of packages) {
                const row = this.makeFclCargoRow();
                row.patchValue(pkg);
                array.push(row);
            }
        } else {
            array.push(this.makeFclCargoRow());
        }

        return array;
    }

    get getCargoFormArray() {
        return this.form.get('packages') as FormArray;
    }

    private makeFclCargoRow(): FormGroup {
        return this.fb.group({
            quantity: [1, [Validators.required, Validators.min(1)]],
            packaging: [null, [Validators.required]],
            unit_weight: [null],
            unit_volume: [null],
            unit_width: [null],
            unit_length: [null],
            unit_height: [null],
            uom: [null],
        });
    }

    private makeLclCargoRow(): FormGroup {
        return this.fb.group({
            quantity: [1, [Validators.required, Validators.min(1)]],
            packaging: [null, [Validators.required]],
            unit_weight: [null, [Validators.required, Validators.min(0.1)]],
            unit_volume: [null],
            unit_length: [null, [Validators.required, Validators.min(1)]],
            unit_width: [null, [Validators.required, Validators.min(1)]],
            unit_height: [null, [Validators.required, Validators.min(1)]],
            uom: ['metric', [Validators.required]],
        });
    }

    ngOnDestroy() {
        this.queryParamsSubscription.unsubscribe();
    }

    setCargoReadyToday() {
        this.form.get('header.cargo_ready').setValue(new Date());
    }

    onUOMSet(index: number, value: any) {
        const control = this.form.get(`packages.${index}.uom`);
        if (value) {
            control.setValue(value.key);
        } else {
            control.setValue(null);
        }
    }

    onVolumeChange(index: number, event): void {
        const control = this.form.get(`packages.${index}`);
        const value = event.target.value;
        let dim = value;
        if (value) {
            if (control.get('uom').value !== 'imperial') {
                dim *= 1e6;
            } else {
                dim *= 1728;
            }
            dim = Math.round(Math.cbrt(dim) * 1000) / 1000;
        }

        ['unit_length', 'unit_width', 'unit_height'].forEach(ctrl => {
            control.get(ctrl).setValue(dim);
        });
    }

    async checkAuthenticated() {
        return this.authenticated$.pipe(first()).toPromise();
    }

    async checkAllowDemo() {
        return this.siteConfig.features$
            .pipe(
                first(),
                map(features =>
                    features['public_quotes_demo']
                        ? features['public_quotes_demo']['value']
                        : false
                )
            )
            .toPromise();
    }

    next(demo: boolean = false): void {
        this.form.markAllAsTouched();
        if (this.form.invalid) {
            return;
        }
        this.checkAuthenticated().then(result => {
            if (!result && !demo) {
                const modalRef = this.modalService.open(LoginModalComponent, {
                    windowClass: 'transparent-dialog',
                    centered: true,
                    size: 'xl',
                });
                this.checkAllowDemo().then(allowDemo => {
                    modalRef.componentInstance.allowContinueWithoutLogin =
                        allowDemo;
                });

                modalRef.result.then(
                    () => {
                        this.next();
                    },
                    reason => {
                        if (reason === 'continue-without-login') {
                            this.next(true);
                        }
                    }
                );
            } else {
                this.form.disable();
                this.busy = true;
                this.error = null;

                const value = this.form.getRawValue();

                let packages = value.packages;
                if (value.header.load_type === 'lcl') {
                    if (this.dimensionsInput) {
                        packages = packages.map(e => {
                            const cubix =
                                e.unit_height * e.unit_width * e.unit_length;
                            if (e.uom === 'metric') {
                                e.unit_volume =
                                    Math.round((cubix / 1e6) * 1000) / 1000;
                            } else {
                                e.unit_volume =
                                    Math.round((cubix / 1728) * 1000) / 1000;
                            }

                            return e;
                        });
                    } else {
                        packages = packages.map(e => {
                            let volume = e.unit_volume;
                            if (e.uom === 'metric') {
                                volume *= 1e6;
                            } else {
                                volume *= 1728;
                            }
                            volume =
                                Math.round(Math.cbrt(volume) * 1000) / 1000;

                            e.unit_length = volume;
                            e.unit_width = volume;
                            e.unit_height = volume;

                            return e;
                        });
                    }
                }

                const payload = {
                    owner_reference: value.header.owner_reference,
                    origin: value.header.origin,
                    destination: value.header.destination,
                    transport_mode: value.header.transport_mode,
                    load_type: value.header.load_type,
                    require_origin_charges: value.header.require_origin_charges,
                    require_destination_charges:
                        value.header.require_destination_charges,
                    cargo_ready: value.header.cargo_ready,
                    commodity: value.header.commodity,
                    currency: value.header.currency,
                    packages: packages.map(e => {
                        e.id = 0;
                        return e;
                    }),
                    additionals: value.additionals,
                };

                this.service
                    .createQuotation(payload, demo)
                    .pipe(take(1))
                    .subscribe(
                        quotation => {
                            this.router.navigate(
                                ['..', quotation.id, 'offers'],
                                {
                                    relativeTo: this.route,
                                    queryParams: {
                                        demo: quotation.is_demo,
                                        temp_token: quotation.temp_token,
                                    },
                                }
                            );
                        },
                        error => {
                            this.form.enable();
                            this.busy = false;
                            this.error = error;
                        }
                    );
            }
        });
    }

    public addCargoRow() {
        (this.form.get('packages') as FormArray).push(this.makeLclCargoRow());
    }

    public removeCargoRow(idx: number) {
        const arr = this.form.get('packages') as FormArray;
        if (arr.length > 1) {
            arr.removeAt(idx);
        }
    }
}
