import { Component, Input } from '@angular/core';
import { LowerCasePipe, NgClass, NgFor, NgIf } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { Portals } from '@qwyk/models';

import {
    ShipmentContainersListItemComponent
} from '../shipment-containers-list-item/shipment-containers-list-item.component';

@Component({
    standalone: true,
    imports: [NgIf, NgFor, NgClass, LowerCasePipe, ShipmentContainersListItemComponent, TranslateModule, FontAwesomeModule],
    selector: 'qwyk-shipment-containers-list',
    templateUrl: './shipment-containers-list.component.html',
    styleUrls: ['./shipment-containers-list.component.scss'],
})
export class ShipmentContainersListComponent {
    @Input() loading = false;
    @Input() shipmentContainers: Portals.ShipmentDataContainer[];
    @Input() cardClass = null;
    @Input() headingClass: string;
    @Input() headerClass: string;
    @Input() dateFormat: string;
}
