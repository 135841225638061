import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';

import {
    SharedStoresShipmentTrackingEventsModule,
    ShipmentTrackingServiceBase,
} from '@qwyk/shared-stores/shipment-tracking-events';
import { LineBreaksPipe, ShipmentTrackingEventsListComponent } from '@qwyk/ui-standalone';

import { ShipmentTrackingEventsViewComponent } from './components';
import { ShipmentTrackingEventsService } from './shipment-tracking-events.service';

@NgModule({
    imports: [
        CommonModule,
        LineBreaksPipe,
        ShipmentTrackingEventsListComponent,
        SharedStoresShipmentTrackingEventsModule.forFeature({
            service: {
                provide: ShipmentTrackingServiceBase,
                useFactory: http => new ShipmentTrackingEventsService(http),
                deps: [HttpClient],
            },
        }),
    ],
    declarations: [ShipmentTrackingEventsViewComponent],
    exports: [ShipmentTrackingEventsViewComponent],
})
export class PortalsShipmentTrackingEventsModule {}
