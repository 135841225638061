import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
    standalone: true,
    imports: [NgClass, FontAwesomeModule, NgbTooltipModule],
    selector: 'qwyk-info',
    templateUrl: './info-tooltip.component.html',
    styleUrls: ['./info-tooltip.component.scss'],
})
export class InfoTooltipComponent {
    @Input() text: string;
    @Input() class = '';
}
