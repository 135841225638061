import { Directive, inject, Input, OnChanges } from '@angular/core';

import { Calendar } from 'primeng/calendar';

@Directive({
  selector: '[qwykPrimengCalendarFix]',
  standalone: true
})
export class PrimengCalendarFixDirective implements OnChanges {

    private _pCalendar = inject(Calendar, { host: true, self: true });

    @Input() dateFormatValue: string;

    public ngOnChanges(): void {
        if (this.dateFormatValue) {
            this._pCalendar.updateInputfield();
            this._pCalendar.cd.detectChanges();
        }
    }

}
