<ng-template #locationIcon let-location let-od="od">
        <span [ngbTooltip]="location.type | titlecase" class="text-secondary">
            <ng-container *ngIf="location.type === 'port'">
                <ng-container [ngSwitch]="quotation.request.transport_mode">
                    <fa-icon *ngSwitchCase="'AIR'"
                             [fixedWidth]="true"
                             [icon]="['fas', od === 'o' ? 'plane-departure' : 'plane-arrival']">
                    </fa-icon>
                    <fa-icon *ngSwitchCase="'OCEAN'" [fixedWidth]="true" [icon]="['fas', 'anchor']">
                    </fa-icon>
                    <fa-icon *ngSwitchCase="'TRUCK'" [fixedWidth]="true"
                             [icon]="['fas', 'warehouse']"></fa-icon>
                    <fa-icon *ngSwitchDefault [fixedWidth]="true" [icon]="['fas', 'map-pin']">
                    </fa-icon>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="location.type !== 'port'">
                <fa-icon [fixedWidth]="true" [icon]="['fas', 'map-pin']"></fa-icon>
            </ng-container>
        </span>
</ng-template>

<div class="row align-items-center text-center text-md-left">
    <div class="col-12 col-md">
        <a [routerLink]="['.', quotation.id]">
            {{quotation.friendly_id}}
        </a>
    </div>
    <div class="col-12 col-md">
        <qwyk-quotation-status-badge [quotation]="quotation"></qwyk-quotation-status-badge>
    </div>
    <div class="col-12 col-md">
        {{ 'portal.shipment.transport-mode.' + quotation.request.transport_mode | translate }} {{ quotation.request.load_type | transportModeTransform: quotation.request.transport_mode | uppercase}}
    </div>
    <div class="col-12 col-md-3 d-flex align-items-center justify-content-center justify-content-md-start">
        <div class="mr-2">
            <ng-container
                    *ngTemplateOutlet="locationIcon, context: {$implicit: quotation.request.origin, od: 'o'}">
            </ng-container>
        </div>
        <div class="overflow-hidden text-truncate">
            {{ quotation.request.origin.name }}
        </div>
    </div>
    <div class="col-12 col-md-3 d-flex align-items-center justify-content-center justify-content-md-start">
        <div class="mr-2">
            <ng-container
                    *ngTemplateOutlet="locationIcon, context: {$implicit: quotation.request.destination, od: 'd'}">
            </ng-container>
        </div>
        <div class="overflow-hidden text-truncate">
            {{ quotation.request.destination.name }}
        </div>
    </div>
    <div class="col-12 col-md text-lg-left">
        <ng-container *ngIf="dateFormat$ | async; let date_format">
            {{ quotation.request.created_at | amLocal | amDateFormat: date_format || 'L' }}
        </ng-container>
    </div>
</div>
