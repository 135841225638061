import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { NgClass, NgIf } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
    standalone: true,
    imports: [NgIf, NgClass, FontAwesomeModule, TranslateModule],
    selector: 'qwyk-full-screen-button',
    templateUrl: './full-screen-button.component.html',
    styleUrls: ['./full-screen-button.component.css'],
})
export class FullScreenButtonComponent implements AfterViewInit {
    @Input() element;
    @Input() disabled = false;
    @Input() buttonClass = 'btn-outline-primary';
    @Input() textLabel = false;
    @Input() public isFullScreen = false;
    @Output() emitter = new EventEmitter<boolean>();

    private _document;

    ngAfterViewInit() {
        this._document = document;
        document.onfullscreenchange = () => {
            this.isFullScreen = !!document.fullscreenElement;
            this.emitter.emit(!!document.fullscreenElement);
        };
    }

    public get canFullscreen(): boolean {
        const e = this.element;
        return (
            e &&
            (e.requestFullscreen ||
                e.msRequestFullscreen ||
                e.mozRequestFullScreen ||
                e.webkitRequestFullscreen)
        );
    }

    public onToggleFullscreen() {
        const elem = this.element;
        if (this._document && this._document.fullscreenElement === null) {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.msRequestFullscreen) {
                elem.msRequestFullscreen();
            } else if (elem.mozRequestFullScreen) {
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) {
                elem.webkitRequestFullscreen();
            }
        } else {
            if (this._document && this._document.fullscreenElement !== null) {
                this._document.exitFullscreen();
            } else if (this._document.mozCancelFullScreen) {
                /* Firefox */
                this._document.mozCancelFullScreen();
            } else if (this._document.webkitExitFullscreen) {
                /* Chrome, Safari and Opera */
                this._document.webkitExitFullscreen();
            } else if (this._document.msExitFullscreen) {
                /* IE/Edge */
                this._document.msExitFullscreen();
            }
        }
        this.isFullScreen = !this.isFullScreen;
    }
}
