<ul class="list-group list-group-flush">
    <ng-container
        *ngFor="let activity of shipmentActivities"
        [ngSwitch]="activity.type"
    >
        <li
            *ngSwitchCase="ActivityType.MILESTONE_CHANGE"
            [shipmentActivity]="activity"
            [dateFormat]="dateFormat"
            class="list-group-item border-0 px-0 activity-item"
            qwyk-shipment-activities-milestone-change
        ></li>
        <li
            *ngSwitchCase="ActivityType.STATUS_UPDATE"
            [shipmentActivity]="activity"
            class="list-group-item border-0 px-0 activity-item"
            qwyk-shipment-activities-status-update
        ></li>
        <li
            *ngSwitchCase="ActivityType.DOCUMENT"
            [shipmentActivity]="activity"
            class="list-group-item border-0 px-0 activity-item"
            qwyk-shipment-activities-document
        ></li>
        <li
            *ngSwitchCase="ActivityType.COMMENT"
            [shipmentActivity]="activity"
            class="list-group-item border-0 px-0 activity-item"
            qwyk-shipment-activities-comment
        ></li>
        <li
            *ngSwitchCase="ActivityType.SCHEDULE_CHANGE"
            [shipmentActivity]="activity"
            class="list-group-item border-0 px-0 activity-item"
            qwyk-shipment-activities-schedule-change
        ></li>
    </ng-container>
    <li
        *ngIf="
            (!shipmentActivities || shipmentActivities.length === 0) && loading
        "
        class="list-group-item border-0 px-0 activity-item"
    >
        <div class="d-flex align-items-center">
            <div class="flex-grow-0 mr-3 icon-container">
                <div
                    class="icon-circle border rounded-circle text-muted bg-white"
                >
                    <fa-icon
                        [fixedWidth]="true"
                        [icon]="['far', 'spinner']"
                        [pulse]="true"
                        size="lg"
                    ></fa-icon>
                </div>
            </div>
            <div class="flex-grow-1" style="margin-top: 0.4em">
                {{'portal.shipment.page.activities.activities-loading' | translate}}
            </div>
        </div>
    </li>
    <li *ngIf="canPaginate" class="list-group-item border-0 px-0 activity-item">
        <div class="d-flex align-items-center">
            <div class="flex-grow-0 mr-3 icon-container">
                <div
                    class="icon-circle border rounded-circle text-muted bg-white"
                >
                    <fa-icon
                        [fixedWidth]="true"
                        [icon]="['far', loading ? 'spinner' : 'ellipsis-h']"
                        [pulse]="loading"
                        size="lg"
                    ></fa-icon>
                </div>
            </div>
            <div class="flex-grow-1" style="margin-top: 0.4em">
                <a (click)="onViewMore()" href="javascript:void(0);"
                    >{{'portal.shipment.page.activities.view-more' | translate}}</a
                >
            </div>
        </div>
    </li>
</ul>
