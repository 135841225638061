import { Pipe, PipeTransform } from '@angular/core';

import { Constants } from '@qwyk/core';

import { IconName } from '@fortawesome/fontawesome-svg-core';

@Pipe({
    standalone: true,
    name: 'portIcon'
})
export class PortIconPipe implements PipeTransform {
    private _portIcons = Constants.TRANSPORT_MODE_ICONS;

    transform(value: string, side: 'pol' | 'pod'): IconName {
        const sideIcons = this._portIcons[side];

        if (sideIcons && typeof sideIcons === 'object') {
            return sideIcons[value] || 'question';
        }

        return 'question';
    }
}
