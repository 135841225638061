import { NgFor, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { common } from '@qwyk/models';
import { ShipmentActivity } from '@qwyk/shared-stores/shipment-activities';

import { ShipmentActivityType } from '../../data/ShipmentActivityType';
import { ShipmentActivitiesDocumentComponent } from '../shipment-activities-document/shipment-activities-document.component';
import { ShipmentActivitiesStatusUpdateComponent } from '../shipment-activities-status-update/shipment-activities-status-update.component';
import { ShipmentActivitiesMilestoneChangeComponent } from '../shipment-activities-milestone-change/shipment-activities-milestone-change.component';
import {
    ShipmentActivitiesCommentComponent
} from '../shipment-activities-comment/shipment-activities-comment.component';
import {
    ShipmentActivitiesScheduleChangeComponent
} from '../shipment-activities-schedule-change/shipment-activities-schedule-change.component';

@Component({
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        NgSwitch,
        NgSwitchCase,
        TranslateModule,
        FontAwesomeModule,
        ShipmentActivitiesCommentComponent,
        ShipmentActivitiesDocumentComponent,
        ShipmentActivitiesStatusUpdateComponent,
        ShipmentActivitiesScheduleChangeComponent,
        ShipmentActivitiesMilestoneChangeComponent,
    ],
    selector: 'qwyk-shipment-activities-list',
    templateUrl: './shipment-activities-list.component.html',
    styleUrls: ['./shipment-activities-list.component.scss'],
})
export class ShipmentActivitiesListComponent {
    @Input() shipmentActivities: ShipmentActivity[];
    @Input() loading: boolean;
    @Input() disabled: boolean;
    @Input() pagination: common.PaginationMeta;
    @Input() dateFormat: string;

    @Output() viewMore: EventEmitter<void> = new EventEmitter<void>();

    public ActivityType = ShipmentActivityType;

    public get canPaginate(): boolean {
        return (
            !!this.pagination &&
            this.pagination.current_page < this.pagination.last_page
        );
    }

    onViewMore() {
        if (this.canPaginate) {
            this.viewMore.emit();
        }
    }
}
