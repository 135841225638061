import { inject, Injectable } from '@angular/core';

import { map, startWith } from 'rxjs/operators';
import { Action, select, Store } from '@ngrx/store';

import { PortalsAuth } from '@qwyk/models';

import * as fromAuthentication from './authentication.reducer';
import * as AuthenticationActions from './authentication.actions';
import * as AuthenticationSelectors from './authentication.selectors';

@Injectable()
export class AuthenticationFacade {

    private readonly store = inject(Store<fromAuthentication.AuthenticationPartialState>);

    public token$ = this.store.pipe(select(AuthenticationSelectors.getToken));
    public userAndToken$ = this.store.pipe(
        select(AuthenticationSelectors.getUserAndToken)
    );
    public authenticated$ = this.store.pipe(
        select(AuthenticationSelectors.getIsAuthenticated)
    );
    public user$ = this.store.pipe(select(AuthenticationSelectors.getUser));
    public error$ = this.store.pipe(select(AuthenticationSelectors.getError));
    public dateFormat$ = this.store.pipe(
        select(AuthenticationSelectors.getDateFormat)
    );
    public calendarDateFormat$ = this.store.pipe(
        select(AuthenticationSelectors.getDateFormat),
        startWith('MM/DD/YYYY'),
        map(format => {
            return this.DATE_FORMAT_MAP[format] ?? null;
        })
    );
    public authenticating$ = this.store.pipe(
        select(AuthenticationSelectors.getIsAuthenticating)
    );
    public redirectOnLogout$ = this.store.pipe(
        select(AuthenticationSelectors.getShouldRedirectOnLogout)
    );
    public permissionsLoaded$ = this.store.pipe(
        select(AuthenticationSelectors.getPermissionsLoaded)
    );

    public permissions$ = this.store.pipe(
        select(AuthenticationSelectors.getPermissions)
    );

    private readonly DATE_FORMAT_MAP = {
        'DD/MM/YYYY': 'dd/mm/yy',
        'MM/DD/YYYY': 'mm/dd/yy',
        'YYYY/MM/DD': 'yy/mm/dd'
    } as const;
    private readonly DEFAULT_CALENDAR_FORMAT = 'yy/mm/dd';

    public initializeAuthenticationState() {
        this.dispatch(AuthenticationActions.initializeAuthenticationState());
    }

    public refreshToken() {
        this.dispatch(AuthenticationActions.refreshToken());
    }

    public tokenReceived(token: PortalsAuth.Token) {
        this.dispatch(AuthenticationActions.newTokenReceived({ token }));
    }

    public login(creds: { email: string; password: string }) {
        const credentials = {
            ...creds,
            organization_id: null,
            site_id: null,
        };
        this.dispatch(
            AuthenticationActions.loginWithCredentials({ credentials })
        );
    }

    public logout() {
        this.dispatch(AuthenticationActions.logout());
    }

    public reset() {
        this.dispatch(AuthenticationActions.reset());
    }

    public setShouldRedirectOnLogout(redirectOnLogout: boolean) {
        this.dispatch(
            AuthenticationActions.setShouldRedirectOnLogout({
                redirectOnLogout,
            })
        );
    }

    public setNewDateFormat(dateFormat: string, language: string): void {
        this.dispatch(AuthenticationActions.setNewDateFormatAndLanguage({ dateFormat, language }));
    }

    private dispatch(action: Action) {
        this.store.dispatch(action);
    }
}
