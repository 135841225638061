import { Component, Input } from '@angular/core';
import { NgClass, NgIf, NgStyle } from '@angular/common';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
    standalone: true,
    imports: [NgIf, NgClass, NgStyle, FontAwesomeModule],
    selector: 'qwyk-contained-icon',
    templateUrl: './contained-icon.component.html',
    styleUrls: ['./contained-icon.component.scss'],
})
export class ContainedIconComponent {
    @Input() icon: any;
    @Input() size = 1;
    @Input() iconScale = 1;
    @Input() containerClass = '';

    public get style() {
        return {
            width: `${this.size}rem`,
            height: `${this.size}rem`,
            'font-size': `${this.size * (this.iconScale * 0.5)}rem`,
            'line-height': `${this.size}rem`,
        };
    }
}
