import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';

import { masterdata } from '@qwyk/models';

@Component({
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        NgSelectModule,
        TranslateModule,
        ReactiveFormsModule,
    ],
    selector: 'qwyk-address',
    templateUrl: './address.component.html',
    styleUrls: ['./address.component.scss'],
})
export class AddressComponent {
    @Input() formGroup: FormGroup;
    @Input() size: 'sm' | null | 'lg' = null;
    @Input() labelsRight = true;
    @Input() formClass = 'my-3';
    @Input() readonly = false;
    @Input() countries: masterdata.Country[];
}
