/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from '@angular/core';
import { Animations } from '@qwyk/ui-standalone';
import { AlgoliaLocationsService, MasterDataService } from '@qwyk/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Portals, ui } from '@qwyk/models';
import { SchedulesSearchFacade } from '@qwyk/portals/schedules-search';
import { SiteConfigFacade } from '@qwyk/portals/siteconfig';
import { BookingWizardConfig } from '../../bookingWizardConfig';
@Component({
    selector: 'qwyk-select-transport-mode-and-routing',
    templateUrl: './select-transport-mode-and-routing.component.html',
    styleUrls: ['./select-transport-mode-and-routing.component.scss'],
    animations: [Animations.fadeInAnimation],
})
export class SelectTransportModeAndRoutingComponent {
    private _loadType: 'fcl' | 'lcl' = null;
    private _transportMode: 'OCEAN' | 'AIR' | 'TRUCK' = null;
    private _origin = null;
    private _destination = null;
    public pickup = false;
    public delivery = false;
    public requireOriginCharges = false;
    public requireDestinationCharges = false;
    public pickupName: string;
    public deliveryName: string;
    public pickupAddress: ui.GooglePlacesAddress;
    public deliveryAddress: ui.GooglePlacesAddress;

    public isSearchingLocations: boolean;
    public locationsSuggestions: any[];
    public originImage = null;
    public destinationImage = null;
    public features$ = this.siteConfig.features$;

    constructor(
        private algolia: AlgoliaLocationsService,
        private router: Router,
        private route: ActivatedRoute,
        private masterData: MasterDataService,
        private schedulesSearch: SchedulesSearchFacade,
        private siteConfig: SiteConfigFacade,
        public config: BookingWizardConfig
    ) {}

    public get loadType(): 'fcl' | 'lcl' {
        return this._loadType;
    }

    public set loadType(loadType: 'fcl' | 'lcl') {
        this._loadType = loadType;
        if (this.transportMode === 'AIR') {
            this.transportMode = null;
        }
    }

    public get transportMode(): 'OCEAN' | 'AIR' | 'TRUCK' {
        return this._transportMode;
    }

    public set transportMode(transportMode: 'OCEAN' | 'AIR' | 'TRUCK') {
        this._transportMode = transportMode;
    }

    public get origin(): any {
        return this._origin;
    }

    public set origin(origin: any) {
        this._origin = origin;
        if (origin && origin.locode) {
            this.getPlaceImage(origin.locode).subscribe(
                image => {
                    this.originImage = image;
                },
                () => {
                    this.originImage = null;
                }
            );
        }
    }

    public get destination(): any {
        return this._destination;
    }

    public set destination(destination: any) {
        this._destination = destination;
        if (destination && destination.locode) {
            this.getPlaceImage(destination.locode).subscribe(
                image => {
                    this.destinationImage = image;
                },
                () => {
                    this.destinationImage = null;
                }
            );
        }
    }

    public navigateToCargoDetails() {
        let origin_code = null;
        if (this.pickup) {
            // tslint:disable-next-line: max-line-length
            origin_code = `G:${this.pickupAddress.placeId}|X:${
                this.pickupAddress.position?.lng
            }|Y:${this.pickupAddress.position?.lat}|C:${
                this.pickupAddress.countryCode
            }|P:${this.pickupAddress.postalCode || ''}`;
        } else {
            origin_code = this.origin.locode;
        }

        let destination_code = null;
        if (this.delivery) {
            // tslint:disable-next-line: max-line-length
            destination_code = `G:${this.deliveryAddress.placeId}|X:${
                this.deliveryAddress.position?.lng
            }|Y:${this.deliveryAddress.position?.lat}|C:${
                this.deliveryAddress.countryCode
            }|P:${this.deliveryAddress.postalCode || ''}`;
        } else {
            destination_code = this.destination.locode;
        }

        this.router.navigate(['..', 'cargo'], {
            relativeTo: this.route,
            queryParams: {
                transportMode: this.transportMode,
                loadType: this.loadType,
                require_oc: this.pickup ? true : this.requireOriginCharges,
                origin_type: this.pickup ? 'place' : 'port',
                origin_code,
                origin_locode: this.origin.locode,
                origin_name: this.pickup
                    ? this.pickupAddress.formattedAddress
                    : this.origin.display_name,
                origin_country: this.origin.country_code,
                require_dc: this.delivery
                    ? true
                    : this.requireDestinationCharges,
                destination_type: this.delivery ? 'place' : 'port',
                destination_code,
                destination_locode: this.destination.locode,
                destination_name: this.delivery
                    ? this.deliveryAddress.formattedAddress
                    : this.destination.display_name,
                destination_country: this.destination.country_code,
            },
        });
    }

    public getSchedule() {
        const query: Portals.SchedulesSearchQuery = {
            origin: this.origin,
            destination: this.destination,
            product:
                this.transportMode === 'AIR'
                    ? 'air'
                    : this.loadType === 'lcl'
                    ? 'csl'
                    : this.loadType,
        };
        this.schedulesSearch.search(query);
        this.router.navigate(['..', 'schedules'], {
            relativeTo: this.route,
        });
    }

    public onLocationsAutocomplete(event: string): void {
        if (event) {
            this.isSearchingLocations = true;

            let prefer = [];
            if (this.transportMode === 'AIR') {
                prefer = ['is_major_airport:true', 'is_airport:true'];
            } else if (this.transportMode === 'OCEAN') {
                prefer = ['is_major_port:true', 'is_port:true'];
            }

            const subscr = this.algolia
                .getLocationSuggestions(event, prefer)
                .subscribe(
                    result => {
                        this.locationsSuggestions = result.slice(0, 5);
                        this.isSearchingLocations = false;
                        subscr.unsubscribe();
                    },
                    () => {
                        this.isSearchingLocations = false;
                        subscr.unsubscribe();
                    }
                );
        } else {
            this.locationsSuggestions = null;
        }
    }

    public onPlaceAutocomplete(
        event: ui.GooglePlacesAddress,
        place: 'pickup' | 'delivery'
    ) {
        if (place === 'pickup') {
            this.pickupAddress = event;
        } else {
            this.deliveryAddress = event;
        }
    }

    private getPlaceImage(locode) {
        return this.masterData.getLocationImage(locode).pipe(first());
    }
}
