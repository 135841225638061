import { Action, createReducer, on } from '@ngrx/store';

import { PortalsAuth } from '@qwyk/models';
import { CustomerPermissions } from '@qwyk/core';

import * as AuthenticationActions from './authentication.actions';

export const AUTHENTICATION_FEATURE_KEY = 'authentication';

export interface State {
    authenticated: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error?: any | null;
    token: string; // last none error (if any)
    user: PortalsAuth.User;
    authenticating: boolean;
    redirectOnLogout: boolean;
    permissions: CustomerPermissions[];
    permissionsLoaded: boolean;
}

export interface AuthenticationPartialState {
    readonly [AUTHENTICATION_FEATURE_KEY]: State;
}

export const initialState: State = {
    authenticated: false,
    token: null,
    user: null,
    error: null,
    authenticating: false,
    redirectOnLogout: true,
    permissions: [],
    permissionsLoaded: false,
};

const authenticationReducer = createReducer(
    initialState,
    on(AuthenticationActions.initializeAuthenticationState, state => ({
        ...state,
    })),
    on(
        AuthenticationActions.initializeAuthenticationStateWithToken,
        (state, { token }) => ({
            ...state,
            token,
            authenticated: true,
        })
    ),
    on(
        AuthenticationActions.initializeAuthenticationStateWithoutToken,
        state => ({
            ...state,
            token: null,
        })
    ),
    on(AuthenticationActions.newTokenReceived, (state, { token }) => ({
        ...state,
        token: token.access_token,
    })),

    on(AuthenticationActions.loadUser, state => ({
        ...state,
        error: null,
    })),
    on(AuthenticationActions.loadUserSuccess, (state, { user }) => ({
        ...state,
        user,
        authenticated: true,
        authenticating: false,
    })),
    on(AuthenticationActions.loadUserFailure, (state, { error }) => ({
        ...state,
        user: null,
        authenticated: false,
        error,
    })),
    on(AuthenticationActions.loginWithCredentials, state => ({
        ...state,
        error: null,
        authenticating: true,
    })),
    on(
        AuthenticationActions.loginWithCredentialsFailure,
        (state, { error }) => ({
            ...state,
            error,
            authenticating: false,
        })
    ),
    on(AuthenticationActions.logoutSuccess, state => ({
        ...state,
        authenticated: false,
        user: null,
    })),
    on(AuthenticationActions.reset, state => ({
        ...state,
        ...initialState,
    })),
    on(
        AuthenticationActions.setShouldRedirectOnLogout,
        (state, { redirectOnLogout }) => ({
            ...state,
            redirectOnLogout,
        })
    ),
    on(AuthenticationActions.loadPermissions, state => ({
        ...state,
        error: null,
    })),
    on(
        AuthenticationActions.loadPermissionsSuccess,
        (state, { permissions }) => ({
            ...state,
            permissions,
            permissionsLoaded: true,
        })
    ),
    on(AuthenticationActions.loadPermissionsFailure, (state, { error }) => ({
        ...state,
        error,
    })),
    on(AuthenticationActions.setNewDateFormatAndLanguage, (state, { dateFormat, language }) => ({
        ...state,
        user: { ...state.user, date_format: dateFormat, language }
    }))
);

export function reducer(state: State | undefined, action: Action) {
    return authenticationReducer(state, action);
}
