<div [ngClass]="cardClass" class="card shadow-sm">
    <div
        *ngIf="showHeader"
        [ngClass]="headerClass"
        class="card-header bg-white border-0 px-4"
    >
        <h5 [ngClass]="headingClass" class="m-0">
            {{'portal.shipment.page.attachments.attachments' | translate}}
        </h5>
    </div>

    <ul class="list-group list-group-flush border-top-0">
        <li class="list-group-item px-4 py-2 bg-white border-top-0">
            <div class="row align-items-center">
                <div class="col-8">
                    {{'portal.shipment.page.tracking-events.description' | translate}}
                </div>
                <div class="col-2">
                    {{'portal.shipment.page.attachments.created' | translate}}
                </div>
                <div class="col-2"></div>
            </div>
        </li>
        <li
            (openCarousel)="onOpenCarousel($event, index)"
            (download)="onDownloadAttachment($event)"
            (delete)="onDeleteAttachment($event)"
            *ngFor="let shipmentAttachment of shipmentAttachments; let index = index"
            [disabled]="loading || disabled"
            [canDelete]="canDelete"
            [shipmentAttachment]="shipmentAttachment"
            class="list-group-item p-4"
            qwyk-shipment-attachments-list-item
        ></li>
        <li
            *ngIf="!loading && (!shipmentAttachments || !shipmentAttachments.length)"
            class="list-group-item p-4 text-center text-muted nothing-found"
        >
            <fa-icon [icon]="['far', 'file']" size="lg"></fa-icon>
            <p class="m-0 mt-2">
                {{'common.no_items_found_short' | translate: {
                value: 'portal.shipment.page.attachments.attachments' | translate
            } }}
            </p>
            <a
                (click)="onUploadAttachment()"
                *ngIf="canUpload"
                class="small"
                href="javascript:void(0);"
            >
                {{'portal.shipment.page.attachments.upload-attachment' | translate}}
            </a>
        </li>
        <li
            *ngIf="loading && !shipmentAttachments?.length"
            class="list-group-item p-4 text-center loader"
        >
            <fa-icon
                [icon]="['far', 'spinner']"
                animation="spin-pulse"
                size="lg"
            ></fa-icon>
            <p class="m-0">{{'portal.shipment.page.attachments.loading' | translate}}</p>
        </li>
    </ul>

    <ng-container *ngIf="carouselData$ | async as cd">
        <qwyk-attachments-carousel
            *ngIf="showCarousel"
            [showCarousel]="showCarousel"
            [currentIndex]="activeIndex"
            [isLoading]="cd.loading"
            [attachmentLinks]="cd.attachmentLinks"
            (saveData)="onSaveData($event)"
            (hideCarousel)="onHideCarousel($event)"
        >
        </qwyk-attachments-carousel>
    </ng-container>

    <div *ngIf="canUpload" class="card-footer bg-white border-0 px-4">
        <button
            (click)="onUploadAttachment()"
            [disabled]="loading || disabled"
            class="btn btn-primary"
        >
            {{'portal.shipment.page.attachments.upload-attachment' | translate}}
        </button>
    </div>
</div>
