import { Component, inject, Input } from '@angular/core';

import { Portals } from '@qwyk/models';
import { AuthenticationFacade } from '@qwyk/portals/authentication';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'li[qwyk-quotations-list-item]',
    templateUrl: './quotations-list-item.component.html',
    styleUrls: ['./quotations-list-item.component.scss'],
})
export class QuotationsListItemComponent {

    private auth = inject(AuthenticationFacade);

    @Input() quotation: Portals.QuotationEntity;

    public dateFormat$ = this.auth.dateFormat$;
}
