import { Pipe, PipeTransform } from '@angular/core';
import { Constants } from '@qwyk/core';
import { IconName } from '@fortawesome/fontawesome-svg-core';

@Pipe({
    standalone: true,
    name: 'purchaseOrderStatusIcon',
})
export class PurchaseOrderStatusIconPipe implements PipeTransform {
    private _icons: any =
        Constants.PURCHASEORDER_STATUS_ICONS;

    transform(value: string): IconName {
        const result: IconName = this._icons[value];
        if (result) {
            return result;
        } else {
            return value as IconName;
        }
    }
}
