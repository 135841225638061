import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { StoreModule } from '@ngrx/store';
import { MomentModule } from 'ngx-moment';
import { EffectsModule } from '@ngrx/effects';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { PortalsQuotationsWithoutRoutingModule } from '@qwyk/portals/quotations';
import {
    SlugToTitlePipe,
    TransportModeIconPipe,
    LoadingIndicatorComponent,
    LocationTypeaheadComponent,
    LocationTypeaheadItemDirective,
    GooglePlacesAutocompleteDirective,
    LocationTypeaheadPlaceholderDirective,
} from '@qwyk/ui-standalone';
import { PortalsSchedulesSearchWithoutRoutingModule } from '@qwyk/portals/schedules-search';

import * as fromBookingWizardOffers from './state/booking-wizard-offers.reducer';
import { BookingWizardOffersFacade } from './state/booking-wizard-offers.facade';
import { BookingWizardOffersEffects } from './state/booking-wizard-offers.effects';

import {
    OfferListComponent,
    SchedulesComponent,
    EnterCargoDetailsComponent,
    BookingWizardContainerComponent,
    SelectTransportModeAndRoutingComponent,
} from './components';
import { BookingWizardConfig } from './bookingWizardConfig';
import { IsOptedOutGuard } from './guards/is-opted-out.guard';
import { QuotationExistsGuard } from './guards/quotation-exists.guard';

@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        FormsModule,
        MomentModule,
        NgSelectModule,
        SlugToTitlePipe,
        FontAwesomeModule,
        ReactiveFormsModule,
        TransportModeIconPipe,
        LoadingIndicatorComponent,
        LocationTypeaheadComponent,
        LocationTypeaheadItemDirective,
        GooglePlacesAutocompleteDirective,
        LocationTypeaheadPlaceholderDirective,
        PortalsQuotationsWithoutRoutingModule,
        PortalsSchedulesSearchWithoutRoutingModule,
        RouterModule.forChild([
            {
                path: '',
                component: BookingWizardContainerComponent,
                children: [
                    {
                        path: '',
                        redirectTo: 'routing',
                        pathMatch: 'full',
                    },
                    {
                        path: 'routing',
                        component: SelectTransportModeAndRoutingComponent,
                        data: {
                            animation: 'RoutingPage',
                        },
                    },
                    {
                        path: 'schedules',
                        component: SchedulesComponent,
                    },
                    {
                        path: 'cargo',
                        component: EnterCargoDetailsComponent,
                        data: {
                            animation: 'CargoPage',
                        },
                    },
                    {
                        path: ':quotationId/offers',
                        component: OfferListComponent,
                        data: {
                            animation: 'OffersPage',
                        },
                        canActivate: [QuotationExistsGuard],
                    },
                ],
                canActivate: [IsOptedOutGuard],
                canActivateChild: [IsOptedOutGuard],
            },
        ]),
        StoreModule.forFeature(
            fromBookingWizardOffers.BOOKINGWIZARDOFFERS_FEATURE_KEY,
            fromBookingWizardOffers.reducer
        ),
        EffectsModule.forFeature([BookingWizardOffersEffects]),
    ],
    declarations: [
        OfferListComponent,
        SchedulesComponent,
        EnterCargoDetailsComponent,
        BookingWizardContainerComponent,
        SelectTransportModeAndRoutingComponent,
    ],
    providers: [BookingWizardOffersFacade],
})
export class PortalsBookingWizardModule {
    static forRoot(
        config: BookingWizardConfig
    ): ModuleWithProviders<PortalsBookingWizardModule> {
        return {
            ngModule: PortalsBookingWizardModule,
            providers: [{ provide: BookingWizardConfig, useValue: config }],
        };
    }
}
