import {
    Component,
    Input,
    HostListener,
    Output,
    EventEmitter,
} from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsEntity } from '../../state/notifications.models';

@Component({
    selector: 'qwyk-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent {
    @Input() notification: NotificationsEntity;
    @Input() expanded: boolean;
    @Output() toggleRead: EventEmitter<void> = new EventEmitter();
    @Output() delete: EventEmitter<void> = new EventEmitter();
    hover: boolean;

    onToggleRead(e) {
        e.preventDefault();
        e.stopPropagation();
        this.toggleRead.emit();
    }

    onDelete(e) {
        e.preventDefault();
        e.stopPropagation();
        this.delete.emit();
    }

    @HostListener('mouseenter')
    onMouseEnter() {
        this.hover = true;
    }

    @HostListener('mouseleave')
    onMouseLeave() {
        this.hover = false;
    }

    onDropdownClick(event, dropdown: NgbDropdown) {
        event.preventDefault();
        event.stopPropagation();
        dropdown.toggle();
        return false;
    }
}
