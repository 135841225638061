import {
    Component,
    OnInit,
    Input,
    OnDestroy,
    Output,
    EventEmitter,
    ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
    AsyncPipe,
    NgClass,
    NgFor,
    NgIf,
    TitleCasePipe,
} from '@angular/common';

import { MomentModule } from 'ngx-moment';
import { debounceTime } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';
import { NgbDropdown, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faHistory, faSearch } from '@fortawesome/pro-regular-svg-icons';

import { ui } from '@qwyk/models';
import { ObjectToKVPPipe } from '../../pipes';

interface Suggestion {
    key: string;
    label?: string | null;
    query?: string | null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function makeQueryKVP(queryTokens: any) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return queryTokens.reduce((prev: any, curr: any) => {
        if (curr.value) {
            // eslint-disable-next-line no-prototype-builtins
            if (!prev.hasOwnProperty(curr.attribute.key)) {
                prev[curr.attribute.key] = curr.value.key;
                prev[curr.attribute.key + '_dpy'] = curr.value.label;
            } else {
                // Property already exists so we need to make the value and array and append the items
                let previous = prev[curr.attribute.key];
                let prevDpy = prev[curr.attribute.key + '_dpy'];
                if (!Array.isArray(previous)) {
                    previous = [previous];
                }
                if (!Array.isArray(prevDpy)) {
                    prevDpy = [prevDpy];
                }

                prev[curr.attribute.key] = [...previous, curr.value.key];
                prev[curr.attribute.key + '_dpy'] = [
                    ...prevDpy,
                    curr.value.label,
                ];
            }
        }

        return prev;
    }, {});
}

@Component({
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        NgClass,
        AsyncPipe,
        FormsModule,
        MomentModule,
        TitleCasePipe,
        ObjectToKVPPipe,
        TranslateModule,
        NgbDropdownModule,
        FontAwesomeModule,
    ],
    selector: 'qwyk-query-builder',
    templateUrl: './query-builder.component.html',
    styleUrls: ['./query-builder.component.scss'],
})
export class QueryBuilderComponent implements OnInit, OnDestroy {
    /**
     * The control size (null, sm or lg.)
     */
    @Input() size: null | 'sm' | 'lg';

    /**
     * Time in ms to use when debouncing the autocomplete.
     */
    @Input()
    public completeDebounceTime = 200;

    /**
     * Array of query builder tokens that can be selected.
     */
    @Input()
    public queries: ui.QueryBuilderQuery[] = [];

    /**
     * Array of preset tokens and their values.
     */
    @Input()
    public tokens: ui.QueryBuilderToken[] = [];

    /**
     * Whether full text search is available.
     */
    @Input()
    public fullTextSearch = true;

    /**
     * Autocomplete Suggestions of query builder component
     */
    private _suggestions: Suggestion[] = [];
    public get suggestions() {
        return this._suggestions;
    }
    @Input()
    public set suggestions(value) {
        this._suggestions = value;
    }

    @Input()
    public loadingSuggestions = false;

    @Input()
    public disabled = false;

    @Input()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public recentSearches: Observable<{ query: any; created_at: Date }[]>;

    private _sortOptions: ui.QueryBuilderSortOption[];
    @Input()
    public set sortOptions(options: ui.QueryBuilderSortOption[]) {
        this._sortOptions = options;
        if (options && options.length > 0) {
            this.selectedSortOption = options[0];
        }
    }
    public get sortOptions(): ui.QueryBuilderSortOption[] {
        return this._sortOptions;
    }

    private _selectedSortOption: ui.QueryBuilderSortOption;
    public set selectedSortOption(option: ui.QueryBuilderSortOption) {
        if (
            !this._selectedSortOption ||
            this._selectedSortOption.key !== option.key
        ) {
            const wasNull = this._selectedSortOption === null;
            this._selectedSortOption = option;
            if (!wasNull) {
                this.search.next(this.queryKVP);
            }
        }
    }
    public get selectedSortOption(): ui.QueryBuilderSortOption {
        return this._selectedSortOption;
    }

    /**
     * Emitter that is triggered when the user hits enter or clicked search.
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() search: EventEmitter<any> = new EventEmitter();

    /**
     * Emitter that is triggered when a new token has been selected or when the autocomplete value changes.
     */
    @Output() loadSuggestions: EventEmitter<Suggestion> = new EventEmitter();

    @ViewChild('controlDropDown', { static: true })
    controlDropDown: NgbDropdown;
    @ViewChild('controlInput')
    controlInput: { nativeElement: { focus: () => void } };

    public editingIdx: number | null = null;
    public controlInputValue: string | null = null;
    public faSearch = faSearch;
    public faHistory = faHistory;

    private tokensCopy: ui.QueryBuilderToken[] = [];
    private acicDebouncerSubscription: Subscription;
    private autocompleteInputChangedDebouncer: BehaviorSubject<Suggestion | null> =
        new BehaviorSubject<Suggestion | null>(null);

    constructor() {
        // Create and subscribe to a debouncer for autocomplete value changes.
        this.acicDebouncerSubscription = this.autocompleteInputChangedDebouncer
            .pipe(debounceTime(this.completeDebounceTime))
            .subscribe(value => {
                if (value) {
                    this.loadSuggestions.next(value);
                }
            });
    }

    private get queryKVP() {
        const kvp = makeQueryKVP(this.queries);
        kvp.sort = this.selectedSortOption.key;
        kvp.direction = this.selectedSortOption.direction;
        return kvp;
    }

    ngOnInit() {
        // Create a copy of tokens so we can restore them on remove events.
        this.tokensCopy = [...this.tokens];
    }

    ngOnDestroy(): void {
        if (this.acicDebouncerSubscription) {
            this.acicDebouncerSubscription.unsubscribe();
        }
    }

    public addFTXToken(input: string): void {
        this.editingIdx =
            this.queries.push({
                attribute: { key: 'freetext', label: '', fulltext: true },
                value: null,
            }) - 1;

        this.setTokenValue({ key: input, label: input });
    }

    public addQueryToken(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        token: any,
        loadSuggestions = true
    ): ui.QueryBuilderQuery {
        this.suggestions = [];

        this.editingIdx =
            this.queries.push({
                attribute: { key: token.key, label: token.label },
                value: null,
            }) - 1;

        this.controlInputValue = null;
        if (loadSuggestions) {
            this.loadSuggestions.next({ key: token.key, label: token.label });

            setTimeout(() => {
                this.controlDropDown.open();
                this.controlInput.nativeElement.focus();
            }, 20);
        }

        // The added token is exclusive so we cannot select it anymore.
        if (token.exclusive) {
            this.tokens.splice(
                this.tokens.findIndex(t => t.key === token.key),
                1
            );
        }

        return token;
    }

    public removeQueryToken(idx: number): void {
        if (this.disabled) {
            return;
        }

        const removedQueryToken = this.queries.splice(idx, 1);
        if (removedQueryToken.length === 0) {
            return;
        }

        // Check if we need to add the token back to the list.
        if (
            !removedQueryToken[0].attribute.fulltext &&
            !this.tokens.find(t => t.key === removedQueryToken[0].attribute.key)
        ) {
            // Yep
            const replacementIdx = this.tokensCopy.findIndex(
                t => t.key === removedQueryToken[0].attribute.key
            );

            // Splicing allows us to keep the original array sorting.
            this.tokens.splice(
                replacementIdx,
                0,
                this.tokensCopy[replacementIdx]
            );
        }
    }

    public onInputBackspace(e: Event) {
        // The input is empty on a backspace.
        if ((e.target as HTMLInputElement).value === '') {
            // So we'll remove the last querytoken.
            this.removeQueryToken(this.queries.length - 1);
            e.preventDefault();
            this.editingIdx = null;
            this.controlDropDown.close();
        }
    }

    public controlInputChanged(e: Event) {
        if (this.editingIdx !== null) {
            this.loadingSuggestions = true;
            const token = this.queries[this.editingIdx];
            this.autocompleteInputChangedDebouncer.next({
                key: token.attribute.key,
                query: (e.target as HTMLInputElement).value,
            });
        } else {
            this.controlDropDown.open();
        }
    }

    public onInputEnter(e: Event) {
        e.preventDefault();
        if (this.editingIdx === null) {
            if (this.controlInputValue) {
                this.addFTXToken(this.controlInputValue);
            } else {
                this.search.next(this.queryKVP);
            }
        } else {
            this.setTokenValue(this.suggestions[0]);
        }
    }

    public setTokenValue(suggestion: Suggestion) {
        if (this.editingIdx !== null) {
            this.queries[this.editingIdx].value = suggestion;
        }
        this.editingIdx = null;
        this.suggestions = [];
        this.controlInputValue = null;
    }

    public onEditToken(idx: number) {
        if (this.disabled) {
            return;
        }
        this.editingIdx = idx;
        this.queries[idx].value = null;
        setTimeout(() => {
            this.controlDropDown.open();
            this.controlInput.nativeElement.focus();
        }, 20);
        this.autocompleteInputChangedDebouncer.next({
            key: this.queries[idx].attribute.key,
            query: null,
        });
    }

    public setQueryFromRecentSearch(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        recentSearch: { query: any; created_at: Date },
        execute = false
    ) {
        const query = recentSearch.query;

        const queryArray = Object.keys(query).map(e => ({
            key: e,
            value: query[e],
        }));

        // Remove all current tokens
        for (let i = 0; i < this.queries.length; i++) {
            this.removeQueryToken(i);
        }

        for (const queryElement of queryArray) {
            if (queryElement.key === 'freetext') {
                this.addFTXToken(queryElement.value.key);
                continue;
            }
            // get the token
            const token = this.tokens.find(t => t.key === queryElement.key);
            if (!token) {
                continue;
            }

            this.addQueryToken(token, false);
            this.setTokenValue(queryElement.value);
        }
        if (execute) {
            this.search.next(this.queryKVP);
        }
    }

    public flipSortDirection(
        selectedSortOption: ui.QueryBuilderSortOption
    ): void {
        selectedSortOption.direction =
            selectedSortOption.direction === 'asc' ? 'desc' : 'asc';
        this.search.next(this.queryKVP);
    }
}
