import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    InvoicesServiceBase,
    SharedStoresInvoicesModule,
} from '@qwyk/shared-stores/invoices';
import { HttpClient } from '@angular/common/http';
import { InvoicesService } from './services/invoices.service';
import { RelatedInvoicesListComponent } from './components';
import { MomentModule } from 'ngx-moment';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingIndicatorComponent } from '@qwyk/ui-standalone';

/*
 * Factory function for the invoices service.
 */
export function invoicesServiceFactory(http: HttpClient): InvoicesServiceBase {
    return new InvoicesService(http);
}

@NgModule({
    imports: [
        CommonModule,
        MomentModule,
        TranslateModule,
        FontAwesomeModule,
        LoadingIndicatorComponent,
        SharedStoresInvoicesModule.forFeature({
            service: {
                provide: InvoicesServiceBase,
                useFactory: invoicesServiceFactory,
                deps: [HttpClient],
            },
        }),
    ],
    declarations: [RelatedInvoicesListComponent],
    exports: [RelatedInvoicesListComponent],
})
export class PortalsInvoicesModule {}
