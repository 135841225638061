import { Pipe, PipeTransform } from '@angular/core';

import { IconName } from '@fortawesome/fontawesome-svg-core';

import { Constants } from '@qwyk/core';

@Pipe({
    standalone: true,
    name: 'transportModeIcon',
})
export class TransportModeIconPipe implements PipeTransform {
    private _transportMode = Constants.TRANSPORT_MODE_ICONS;

    transform(
        value: string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
        ...args: any[]
    ): IconName {
        // eslint-disable-next-line no-prototype-builtins
        if (this._transportMode.hasOwnProperty(value) && typeof this._transportMode[value] === 'string') {
            return this._transportMode[value] as IconName;
        }
        return 'question' as IconName;
    }
}
