import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromNotifications from './notifications.reducer';
import * as NotificationsActions from './notifications.actions';
import { PortalsNotificationsService } from '../portals-notifications.service';
import { EMPTY, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { NotificationsEntity } from './notifications.models';
import { Update } from '@ngrx/entity';
import { select, Store } from '@ngrx/store';
import * as NotificationsSelectors from './notifications.selectors';
import { PortalsSocketNotificationsService } from '../portals-socket-notifications.service';

@Injectable()
export class NotificationsEffects {
    loadNotifications$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotificationsActions.loadNotifications),
            switchMap(() =>
                this.notificationsService.getNotifications().pipe(
                    map(notifications =>
                        NotificationsActions.loadNotificationsSuccess({
                            notifications,
                        }),
                    ),
                    catchError(error =>
                        of(
                            NotificationsActions.loadNotificationsFailure({
                                error,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    loadNotificationsFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(NotificationsActions.loadNotificationsFailure),
                map(action => {
                    this.router.navigate(
                        [`/my-portal/error/${action.error.status}`],
                        {
                            queryParams: {
                                source: this.router.url,
                                errorName: action.error.statusText,
                            },
                        },
                    );
                }),
            ),
        { dispatch: false },
    );

    loadMoreNotifications$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotificationsActions.loadMoreNotifications),
            withLatestFrom(
                this.store.pipe(select(NotificationsSelectors.getLoadedPage)),
            ),
            mergeMap(action =>
                this.notificationsService.getNotifications(action[1] + 1).pipe(
                    map(notifications =>
                        NotificationsActions.loadNotificationsSuccess({
                            notifications,
                        }),
                    ),
                    catchError(error =>
                        of(
                            NotificationsActions.loadNotificationsFailure({
                                error,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    deleteNotification$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotificationsActions.deleteNotification),
            mergeMap(action =>
                this.notificationsService
                    .deleteNotification(action.notification)
                    .pipe(
                        map(unread =>
                            NotificationsActions.deleteNotificationSuccess({
                                unread,
                            }),
                        ),
                        catchError(error =>
                            of(
                                NotificationsActions.deleteNotificationFailure({
                                    error,
                                    restore: action.notification,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );

    deleteNotificationsSettingFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(NotificationsActions.deleteNotificationFailure),
                map(() => {
                    this.toastr.error(
                        'Something went wrong deleting your notification.',
                    );
                }),
            ),
        { dispatch: false },
    );

    toggleNotificationRead$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotificationsActions.toggleNotificationRead),
            mergeMap(action =>
                this.notificationsService
                    .toggleNotificationRead(action.notification)
                    .pipe(
                        map(unread =>
                            NotificationsActions.toggleNotificationReadSuccess({
                                unread,
                            }),
                        ),
                        catchError(error =>
                            of(
                                NotificationsActions.toggleNotificationReadFailure(
                                    {
                                        error,
                                        restore: action.notification,
                                    },
                                ),
                            ),
                        ),
                    ),
            ),
        ),
    );

    toggleNotificationReadFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(NotificationsActions.toggleNotificationReadFailure),
                map(() => {
                    this.toastr.error(
                        'Something went wrong updating your notification.',
                    );
                }),
            ),
        { dispatch: false },
    );

    selectNotification$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotificationsActions.selectNotification),
            mergeMap(action => {
                if (!action.notification.read) {
                    const notification: Update<NotificationsEntity> = {
                        id: action.notification.id as string,
                        changes: { read: true },
                    };
                    return of(
                        NotificationsActions.toggleNotificationRead({
                            notification,
                        }),
                    );
                } else {
                    return EMPTY;
                }
            }),
        ),
    );

    loadUnreadNotifications$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotificationsActions.loadUnreadNotifications),
            switchMap(() =>
                this.notificationsService.getUnreadNotificationsCount().pipe(
                    map(unread =>
                        NotificationsActions.loadUnreadNotificationsSuccess({
                            unread,
                        }),
                    ),
                    catchError(error =>
                        of(
                            NotificationsActions.loadUnreadNotificationsFailure(
                                { error },
                            ),
                        ),
                    ),
                ),
            ),
        ),
    );

    initializeEchoListener = createEffect(() =>
        this.actions$.pipe(
            ofType(NotificationsActions.initializeEchoListener),
            switchMap(action =>
                this.echoService
                    .registerEchoListener(action.token, action.userId)
                    .pipe(
                        map(notification =>
                            NotificationsActions.echoNotificationReceived({
                                notification,
                            }),
                        ),
                        takeUntil(
                            this.actions$.pipe(
                                ofType(
                                    NotificationsActions.deinitializeEchoListener,
                                ),
                            ),
                        ),
                    ),
            ),
        ),
    );

    deinitializeEchoListener = createEffect(
        () =>
            this.actions$.pipe(
                ofType(NotificationsActions.deinitializeEchoListener),
                map(() => this.echoService.deregisterEchoListener()),
            ),
        { dispatch: false },
    );

    echoNotificationReceived$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(NotificationsActions.echoNotificationReceived),
                map(action => {
                    this.notificationsService.browserNotification$.next(action.notification);
                    this.notificationsService.showNotificationToast(
                        action.notification,
                    );
                }),
            ),
        { dispatch: false },
    );

    constructor(
        private actions$: Actions,
        private notificationsService: PortalsNotificationsService,
        private echoService: PortalsSocketNotificationsService,
        private toastr: ToastrService,
        private router: Router,
        private store: Store<fromNotifications.NotificationsPartialState>,
    ) {
    }
}
