<ng-template #locationIcon let-location let-od="od" let-tm="tm">
    <ng-container *ngIf="location.type === 'port'">
        <ng-container [ngSwitch]="tm">
            <ng-container *ngSwitchCase="'AIR'">{{ 'portal.landing-page.quotation-request.location-select.option-airport' | translate }}</ng-container>
            <ng-container *ngSwitchCase="'OCEAN'">
                {{ 'portal.landing-page.quotation-request.location-select.option-port' | translate }}
            </ng-container>
            <ng-container *ngSwitchCase="'TRUCK'">{{ 'portal.landing-page.quotation-request.location-select.option-terminal' | translate }}</ng-container>
            <ng-container *ngSwitchDefault>
                {{ 'portal.landing-page.quotation-request.location-select.option-port' | translate }}
            </ng-container>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="location.type !== 'port'">
        {{ 'portal.landing-page.quotation-request.location-select.option-door' | translate }}
    </ng-container>
</ng-template>

<p-table
    [loading]="loading"
    [rowHover]="true"
    [value]="quotations"
    [responsiveLayout]="'scroll'"
    dataKey="id">

    <ng-template pTemplate="caption"></ng-template>

    <ng-template class="table-align" let-columns pTemplate="header">
        <tr>
            <th>{{'common.quotations.quotation' | translate| titlecase}} #</th>
            <th style="width: 140px;">{{'common.sort_options.status' | translate | titlecase}}</th>
            <th>{{'portal.shipment.page.instructions.transport-mode' | translate | titlecase}}</th>
            <th>{{'portal.shipment.load-type' | translate | titlecase}}</th>
            <th colspan="2">{{'portal.shipment.table.columns.origin' | translate | titlecase}}</th>
            <th colspan="2">{{'common.quotations.table.destination' | translate | titlecase}}</th>
            <th>{{'common.quotations.table.requested-currency' | translate | titlecase}}</th>
            <th>{{'portal.shipment.page.instructions.cargo-ready' | translate | titlecase}}</th>
            <th>{{'common.quotations.table.offered-rates' | translate | titlecase}}</th>
            <th>{{'common.created' | translate |titlecase}}</th>
        </tr>
    </ng-template>

    <ng-template let-expanded="expanded" let-q pTemplate="body">

        <tr [routerLink]="['./', q.id]" style="cursor: pointer;" *ngIf="dateFormat$ | async; let date_format">
            <td>
                {{ q.friendly_id}}
            </td>
            <td>
                <fa-icon *ngIf="q.status | valueMap:icons; let i" [fixedWidth]="true" [icon]="['far', i]"
                         class="mr-1"></fa-icon>
                {{ (labels[q.status] ? labels[q.status]: '') | translate }}
            </td>
            <td>
                {{ 'portal.shipment.transport-mode.' + q.request.transport_mode | translate }}
            </td>
            <td>
                <span *ngIf="q.request.transport_mode !== 'AIR'">
                    {{ q.request.load_type | transportModeTransform: q.request.transport_mode | uppercase}}
                </span>
            </td>
            <td>
                <ng-container
                    *ngTemplateOutlet="locationIcon, context: {$implicit: q.request.origin, od: 'o', tm: q.request.transport_mode}">
                </ng-container>
            </td>
            <td>
                {{ q.request.origin.name}}
            </td>
            <td>
                <ng-container
                    *ngTemplateOutlet="locationIcon, context: {$implicit: q.request.destination, od: 'd', tm: q.request.transport_mode}">
                </ng-container>
            </td>
            <td>
                {{ q.request.destination.name}}
            </td>
            <td>
                {{ q.request.currency}}
            </td>
            <td>
                {{ q.request.cargo_ready | amLocal | amDateFormat: date_format || 'L' }}
            </td>
            <td>
                {{ q.offers_count }}
            </td>
            <td>
                {{ q.request.created_at | amLocal | amDateFormat: date_format || 'L' }}
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
        <tr>
            <td class="text-center" colspan="12">
                <div>{{ 'common.no_items_found_long' | translate: {
                    value: 'common.quotations.quotations' | translate
                } }}</div>
            </td>
        </tr>
    </ng-template>
</p-table>
