import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { first, map, take } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SegmentService } from 'ngx-segment-analytics';
import { TranslateService } from '@ngx-translate/core';

import {
    AuthenticationFacade,
    LoginModalComponent,
} from '@qwyk/portals/authentication';
import { Portals } from '@qwyk/models';
import { Animations } from '@qwyk/ui-standalone';
import { SiteConfigFacade } from '@qwyk/portals/siteconfig';
import { AlgoliaLocationsService, SegmentEvent } from '@qwyk/core';


import { SchedulesSearchFacade } from '../../state/schedules-search.facade';
import { SchedulesSearchService } from '../../services/schedules-search.service';

@Component({
    selector: 'qwyk-schedule-search',
    templateUrl: './schedule-search.component.html',
    styleUrls: ['./schedule-search.component.scss'],
    animations: [
        Animations.expandAnimation,
        Animations.fadeInAnimation,
        Animations.expandCollapse2,
    ],
})
export class ScheduleSearchComponent implements OnInit, OnDestroy {
    public locationsSuggestions;
    public controlSize = 'lg';
    public searchForm: FormGroup;
    public isSearchingLocations = false;
    public expandedSchedule: number;
    public schedulesResultPage = 1;
    public pageSize = 10;
    public mouseover = null;
    public now = new Date();

    public loaded$ = this.schedulesSearch.loaded$;
    public searching$ = this.schedulesSearch.searching$;
    public schedules$ = this.schedulesSearch.schedules$;
    public schedulesResult$: Observable<Portals.Schedule[]> =
        this.schedules$.pipe(
            map(schedules =>
                schedules.map(schedule => {
                    schedule.carrier =
                        schedule.legs[0]?.carrier ?? schedule.carrier;
                    return schedule;
                })
            )
        );
    public error$ = this.schedulesSearch.error$;
    public query$ = this.schedulesSearch.query$;
    public authenticated$ = this.authentication.authenticated$;
    public features$ = this.siteConfig.features$;

    // public isSearchingSchedules$ = this.store.select(
    //     fromSchedules.isSearchingSchedules
    // );
    // public hasSearchingFailed$ = this.store.select(
    //     fromSchedules.hasSearchingFailed
    // );
    // public searchResults$ = this.store.select(fromSchedules.getSearchResults);
    // public searchQuery$ = this.store.select(fromSchedules.getSearchQuery);

    private searchQuerySubscription: Subscription;
    private isSearchingSubscription: Subscription;
    private queryFormSubscription: Subscription;
    private routeSubscription: Subscription;

    public savingScheduleIdx: number = null;
    public linkToBookingId: string;

    constructor(
        private algoliaLocations: AlgoliaLocationsService,
        private fb: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private schedulesSearch: SchedulesSearchFacade,
        private authentication: AuthenticationFacade,
        private service: SchedulesSearchService,
        private modalService: NgbModal,
        private segment: SegmentService,
        private siteConfig: SiteConfigFacade,
        private _translate: TranslateService
    ) {}

    ngOnInit() {
        this.initSearchForm();

        this.searchQuerySubscription = this.query$.subscribe(() => {
            // this.displayQueryOnMap(query);
        });

        this.isSearchingSubscription = this.searching$.subscribe(searching => {
            if (searching) {
                this.searchForm.disable();
            } else {
                this.searchForm.enable();
            }
        });

        this.queryFormSubscription = this.searchForm.valueChanges.subscribe(
            () => {
                // this.displayQueryOnMap(query);
            }
        );

        this.routeSubscription = this.route.queryParams.subscribe(params => {
            this.linkToBookingId = params.bkg;

            if (
                params.oc &&
                params.on &&
                params.dc &&
                params.dn &&
                params.mod
            ) {
                this.searchForm.get('origin').setValue({
                    locode: params.oc,
                    display_name: params.on,
                });

                this.searchForm.get('destination').setValue({
                    locode: params.dc,
                    display_name: params.dn,
                });

                this.searchForm.get('product').setValue(params.mod);

                this.search();
            }
        });
    }

    ngOnDestroy(): void {
        if (this.queryFormSubscription) {
            this.queryFormSubscription.unsubscribe();
        }

        if (this.isSearchingSubscription) {
            this.isSearchingSubscription.unsubscribe();
        }

        if (this.searchQuerySubscription) {
            this.searchQuerySubscription.unsubscribe();
        }

        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
        }

        this.schedulesSearch.reset();
    }

    private initSearchForm() {
        this.searchForm = this.fb.group({
            origin: [null, [Validators.required]],
            destination: [null, [Validators.required]],
            product: [null, [Validators.required]],
        });

        this.query$.pipe(take(1)).subscribe(query => {
            if (query) {
                this.searchForm.patchValue({ ...query, source: '' });
            }
        });
    }

    public search() {
        this.searchForm.markAllAsTouched();
        if (this.searchForm.invalid) {
            return;
        }
        this.reset();
        this.controlSize = null;

        const query = this.searchForm.value as Portals.SchedulesSearchQuery;
        this.segment.track(SegmentEvent.SCHEDULE_SEARCH, query);
        this.schedulesSearch.search(query);
    }

    public flipLocations() {
        const destination = this.searchForm.get('origin').value;
        const origin = this.searchForm.get('destination').value;
        this.searchForm.patchValue({
            origin,
            destination,
        });
    }

    public onAutocomplete(event: string) {
        if (event) {
            this.isSearchingLocations = true;
            const subscr = this.algoliaLocations
                .getLocationSuggestions(event)
                .subscribe(
                    result => {
                        this.locationsSuggestions = result;
                        this.isSearchingLocations = false;
                        subscr.unsubscribe();
                    },
                    () => {
                        this.isSearchingLocations = false;
                        subscr.unsubscribe();
                    }
                );
        } else {
            this.locationsSuggestions = null;
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public locationSelected(control: string, location: any) {
        let valueToSet = null;
        if (location) {
            valueToSet = {
                locode: location.locode,
                display_name: location.display_name,
                country_code: location.country_code,
                country_name: location.country_name,
                position: location._geoloc,
            };
        }
        this.searchForm.get(control).patchValue(valueToSet);
    }

    public toggleControlSize() {
        this.controlSize = this.controlSize ? null : 'lg';
    }

    public toggleExpandedSchedule(idx: number, e): boolean {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        if (this.expandedSchedule !== idx) {
            this.expandedSchedule = idx;
        } else {
            this.expandedSchedule = null;
        }
        return false;
    }

    private reset(): void {
        this.expandedSchedule = null;
        this.schedulesResultPage = 1;
        this.filter(null);
    }

    public book(schedule, idx): void {
        this.save(schedule, idx, true);
    }

    async checkAuthenticated() {
        return this.authenticated$.pipe(first()).toPromise();
    }

    public save(
        schedule: Portals.Schedule,
        idx: number,
        continueToBook = false
    ): void {
        this.checkAuthenticated().then(result => {
            if (!result) {
                this.modalService
                    .open(LoginModalComponent, {
                        windowClass: 'transparent-dialog',
                        centered: true,
                        size: 'xl',
                    })
                    .result.then(
                        () => {
                            this.save(schedule, idx, continueToBook);
                        },
                        () => {}
                    );
            } else {
                this.segment.track(SegmentEvent.SCHEDULE_SAVED);
                this.savingScheduleIdx = idx;
                let subscription = null;
                subscription = this.service.saveSchedule(schedule).subscribe(
                    savedSchedule => {
                        this.savingScheduleIdx = null;
                        if (subscription) {
                            subscription.unsubscribe();
                        }
                        if (this.linkToBookingId) {
                            this.service
                                .updateBookingWithSchedule(
                                    savedSchedule.id,
                                    this.linkToBookingId
                                )
                                .subscribe(
                                    () => {
                                        this.router.navigate([
                                            '/my-portal/booking/new',
                                            this.linkToBookingId,
                                        ]);
                                    },
                                    () => {
                                        this.savingScheduleIdx = null;
                                        this.toastr.error(
                                            this._translate.instant(
                                                'common.try_again'
                                            ),
                                            this._translate.instant(
                                                'common.something_went_wrong'
                                            )
                                        );
                                    }
                                );
                        } else if (continueToBook) {
                            this.segment.track(
                                SegmentEvent.SCHEDULE_CONVERTED,
                                {
                                    id: savedSchedule.id,
                                    destination: 'shipment',
                                }
                            );
                            this.router.navigate(
                                [
                                    '/my-portal/schedules/saved/',
                                    savedSchedule.id,
                                ],
                                { queryParams: { action: 'book' } }
                            );
                        } else {
                            const view_this = this._translate.instant(
                                'portal.schedules.search.view-this'
                            );
                            const view_all = this._translate.instant(
                                'portal.schedules.search.view-all'
                            );
                            let content = `<a href="/my-portal/schedules/saved/${savedSchedule.id}">${view_this}</a><br />`;
                            content += `<a href="/my-portal/schedules/saved">${view_all}</a>`;
                            this.toastr.success(
                                content,
                                this._translate.instant(
                                    'portal.schedules.search.saved-schedule'
                                ),
                                {
                                    enableHtml: true,
                                }
                            );
                        }
                    },
                    () => {
                        this.savingScheduleIdx = null;
                        this.toastr.error(
                            this._translate.instant('common.try_again'),
                            this._translate.instant(
                                'common.something_went_wrong'
                            )
                        );
                        if (subscription) {
                            subscription.unsubscribe();
                        }
                    }
                );
            }
        });
    }

    public filter($event = null) {
        this.schedulesResult$ = this.schedules$.pipe(
            map(schedules => {
                if ($event) {
                    return schedules
                        .filter(schedule =>
                            this.filterDateBetween('etd', schedule, $event)
                        )
                        .filter(schedule =>
                            this.filterDateBetween('eta', schedule, $event)
                        )
                        .filter(schedule =>
                            this.filterDateBetween('closing', schedule, $event)
                        )
                        .filter(schedule =>
                            this.filterSelected('voyage', schedule, $event)
                        )
                        .filter(schedule =>
                            this.filterSelected('vessel', schedule, $event)
                        )
                        .filter(schedule =>
                            this.filterCarrier(schedule, $event)
                        );
                }
                return schedules;
            })
        );
    }

    public filterDateBetween(
        field: string,
        schedule: Portals.Schedule,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        $event: any
    ) {
        if ($event[field] && $event[field].length > 0) {
            return (
                moment(schedule[field]).isBetween(
                    moment($event[field][0]).startOf('day'),
                    moment($event[field][1] || $event[field][0]).endOf('day')
                ) ||
                moment(schedule[field]).isSame(
                    moment($event[field][0]).startOf('day')
                )
            );
        }
        return true;
    }

    public filterSelected(
        field: string,
        schedule: Portals.Schedule,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        $event: any
    ) {
        if ($event[field] && $event[field].length > 0) {
            return $event[field].includes(schedule[field]);
        }
        return true;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public filterCarrier(schedule: Portals.Schedule, $event: any) {
        if ($event.carrier && $event.carrier.length > 0) {
            return $event.carrier.includes(schedule.carrier.code);
        }
        return true;
    }
}
