import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Route } from '@angular/router';

import { StoreModule } from '@ngrx/store';
import { MomentModule } from 'ngx-moment';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import * as fromNotifications from './state/notifications.reducer';
import { NotificationsEffects } from './state/notifications.effects';
import { NotificationsFacade } from './state/notifications.facade';
import { NotificationComponent } from './components/notification/notification.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { NotificationDetailComponent } from './components/notification-detail/notification-detail.component';
import {
    NotificationIconPipe,
    LoadingIndicatorComponent,
    ModuleInfoButtonComponent,
} from '@qwyk/ui-standalone';

export const portalsNotificationsRoutes: Route[] = [
    {
        path: '',
        component: NotificationsComponent,
    },
];

@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        MomentModule,
        RouterModule,
        TranslateModule,
        FontAwesomeModule,
        NotificationIconPipe,
        LoadingIndicatorComponent,
        ModuleInfoButtonComponent,
        StoreModule.forFeature(
            fromNotifications.NOTIFICATIONS_FEATURE_KEY,
            fromNotifications.reducer
        ),
        EffectsModule.forFeature([NotificationsEffects]),
    ],
    declarations: [
        NotificationsComponent,
        NotificationComponent,
        NotificationDetailComponent,
    ],
    providers: [NotificationsFacade],
})
export class PortalsNotificationsModule {}
