import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgFor, TitleCasePipe } from '@angular/common';

import { MomentModule } from 'ngx-moment';
import { TranslateModule } from '@ngx-translate/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { ObjectToKVPPipe } from '../../pipes';
import { ShipmentActivitiesListItemComponent } from '../shipment-activities-list-item/shipment-activities-list-item.component';

@Component({
    standalone: true,
    imports: [NgFor, RouterLink, TitleCasePipe, ObjectToKVPPipe, NgbTooltipModule, TranslateModule, FontAwesomeModule, MomentModule],
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'li[qwyk-shipment-activities-schedule-change]',
    templateUrl: './shipment-activities-schedule-change.component.html',
    styleUrls: ['./shipment-activities-schedule-change.component.scss'],
})
export class ShipmentActivitiesScheduleChangeComponent extends ShipmentActivitiesListItemComponent {}
